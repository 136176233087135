import visaLogo from "../images/card-logos/visa.png";
import masterLogo from "../images/card-logos/master.png";
import amexLogo from "../images/card-logos/amex.png";
import jcbLogo from "../images/card-logos/jcb.png";
import dinersLogo from "../images/card-logos/diners.png";
import discoveryLogo from "../images/card-logos/discovery.png";


export const CardTypes = [
        {
            name: "VISA",
            pattern: /^4[0-9]{12}(?:[0-9]{3})?$/,
            logo: visaLogo,
        },
        {
            name: "MASTERCARD",
            pattern: /^5[1-5][0-9]{14}$/,
            logo: masterLogo,
        },
        {
            name: "AMEX",
            pattern: /^3[4][0-9]{14}$/,
            logo: amexLogo,
        },
        {
            name: "JCB",
            pattern: /^(?:2131|1800|35\d{3})\d{11}$/,
            logo: jcbLogo,
        },
        {
            name: "DINERS",
            pattern: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
            logo: dinersLogo,
        },
        {
            name: "DISCOVER",
            pattern: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
            logo: discoveryLogo,
        },
    ];

export function getCardType(number) {
    const numberFormated = number.replace(/\D/g, "");

    var matched = CardTypes.find(c=> c.pattern.test(numberFormated));
    
    if(matched){
    return {
        name: matched.name,
        logo: matched.logo
    };
}else {

return null;
}
}
