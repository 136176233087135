import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  EMPTY_CART,
  CHANGE_PRODUCT_QUANTITY,
  IS_CART_ITEM_EDIT,
  UPDATE_CART_ITEM,
} from "../../actions/public/actionTypes";

const initialStates = {
  cart: [],
  cartTotal: 0,
  cartTaxTotal: 0,
  productTracker: [],
  isEdit: false,
  carttype: "",
};

const _calcCartTotal = (cartitems) => {
  let total = 0;
  cartitems.forEach((item) => {
    total += item.unitcost * item.quantityincart;
  });
  return total;
};

const _calcCartTaxTotal = (cartitems) => {
  let charge = 0;
  let taxTotal = 0;
  cartitems.forEach((item) => {
    charge = item.unitcost * item.quantityincart;
    taxTotal += parseFloat(charge) * parseFloat(item.taxrate);
  });
  return taxTotal;
};

const CartReducer = (state = initialStates, action) => {
  let tempCart;
  let tempProductTracker;

  switch (action.type) {
    case ADD_TO_CART:
      const productExistInCart = state.cart.find(
        (p) =>
          p.productid === action.payload.productid &&
          p.storeid === action.payload.storeid
      );
      if (productExistInCart) {
        productExistInCart.quantityincart = action.payload.quantityincart || 1;
        return {
          cart: [...state.cart, productExistInCart],
          ...state,
        };
      } else {

        const product = {
          vendorid: action.payload.vendorid,
          vendorname: action.payload.vendorname,
          productid: action.payload.productid,
          productreferenceid: action.payload.productreferenceid,
          productname: action.payload.productname,

          unitcost: action.payload.unitcost,
          // quantity: action.payload.quantity || 1,
          quantityincart: action.payload.quantityincart || 1,
          total: action.payload.unitcost,
          taxrate: action.payload.taxrate,
          categoryid: action.payload.categoryid,
        };

        tempCart = [product, ...state.cart];

        const productracker = {
          vendorid: action.payload.vendorid,
          productid: action.payload.productid,
          rootproductid: action.payload.rootproductid,
        };

        tempProductTracker = [productracker, ...state.productTracker];

        return {
          ...state,
          cart: tempCart,
          cartTotal: _calcCartTotal(tempCart),
          cartTaxTotal: _calcCartTaxTotal(tempCart),
          productTracker: tempProductTracker,
        };
      }

    case REMOVE_FROM_CART:
      tempCart = state.cart.filter(
        (item) => item.productid !== action.payload.item.productid
      );

      tempProductTracker = state.productTracker.filter(
        (item) => item.productid !== action.payload.item.productid
      );

      return {
        ...state,
        cart: tempCart,
        cartTotal: _calcCartTotal(tempCart),
        cartTaxTotal: _calcCartTaxTotal(tempCart),
        productTracker: tempProductTracker,
      };

    case EMPTY_CART:
      return {
        ...state,
        cart: [],
        cartTotal: 0,
        cartTaxTotal: 0,
        isEdit: false,
      };
    case CHANGE_PRODUCT_QUANTITY:
      tempCart = [...state.cart];
      tempProductTracker = [...state.productTracker];

      tempCart.forEach((product) => {
        if (product.productid === action.payload.productId) {
          product.quantityincart = action.payload.quantityincart;
          product.total = product.quantityincart * product.unitcost;
          return;
        }
      });

      return {
        ...state,
        cart: tempCart,
        cartTotal: _calcCartTotal(state.cart),
        cartTaxTotal: _calcCartTaxTotal(state.cart),
      };
    case IS_CART_ITEM_EDIT:
      return {
        ...state,
        isEdit: action.payload,
      };

    case UPDATE_CART_ITEM:
      tempCart = [...state.cart];
      tempProductTracker = [...state.productTracker];

      const product = {
        vendorid: action.payload.vendorid,
        vendorname: action.payload.item.vendorname,

        productid: action.payload.item.productid,
        productreferenceid: action.payload.item.productreferenceid,
        productname: action.payload.item.productname,

        unitcost: action.payload.item.unitcost,
        quantityincart: action.payload.item.quantityincart,
        total: action.payload.item.unitcost,
        taxrate: action.payload.item.taxrate,
      };

      const productracker = {
        vendorid: action.payload.vendorid,
        productid: action.payload.item.productid,
        rootproductid: tempProductTracker[action.payload.index].rootproductid,
      };

      tempCart[action.payload.index] = product;

      tempProductTracker[action.payload.index] = productracker;

      return {
        ...state,
        cart: tempCart,
        cartTotal: _calcCartTotal(tempCart),
        cartTaxTotal: _calcCartTaxTotal(tempCart),
        cartGrandTotal: _calcCartTotal(tempCart) + _calcCartTaxTotal(tempCart),
        productTracker: tempProductTracker,
      };

    default:
      return state;
  }
};

export default CartReducer;
