import { APPLICATION_CONST } from "../../config/customConfig";
import axios from "axios";
import md5 from "md5";

export const callChangePasswordService = (data) => {
  return new Promise((resolve, reject) => {
    const body = {
      username: data.username,
      sessionid: data.sessionid,
      failstatus: 0,
      request: {
        oldpassword: md5(data.oldPassword),
        newpassword: md5(data.newPassword),
      },
    };

    axios({
      method: "post",
      url: APPLICATION_CONST.url + "changepassword",
      data: body,
    })
      .then((response) => {
        let resoponseData = response.data;
 
        if (resoponseData.status === 0) {
          resolve({
            success: true,
            statusMessage: resoponseData.statusMessage,
            data: resoponseData.data,
          });
        } else if (resoponseData.status > 0) {
          resolve({
            success: false,
            statusMessage: resoponseData.statusMessage,
            data: {},
          });
        } else {
          resolve({
            success: false,
            statusMessage: "Something went wrong",
            data: {},
          });
        }
      })
      .catch((error) => {
        resolve({
          success: false,
          statusMessage: error.message,
          data: {},
        });
      });
  });
};
