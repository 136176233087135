import "./App.css";
import "antd/dist/antd.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";

import React, { Component } from "react";

import routes from "./config/routeConfig";
import { SUBSCRIBER_LOGIN } from "./config/customConfig";

import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.css";

import { callLoginService } from "./actions/authentication/signInAction";

import SignUp from "./components/SignUp";
import SignIn from "./components/SignIn";
import NoMatch from "./components/common/noMatch";
import Home from "./screens/Home";
import ResetPassword from "./components/ResetPassword";

class App extends Component {
  constructor(props) {
    super(props);
    const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));
  }

  componentDidMount() {
    const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

    let i = 0;

    setInterval(() => {
      i++;
      const signInDetail = JSON.parse(localStorage.getItem("loginDetail"));
      if (signInDetail) {
        const loginTime = signInDetail.time;
        const now = new Date();
        const currenttime = now.getTime();
        if ((currenttime - loginTime) / 1000 / 60 > 50) {
          const data = {
            failstatus: 0,
            username: signInDetail.username,
            password: signInDetail.password,
            terms: signInDetail.terms,
            silentLogin: true,
            internal: signInDetail.internal,
          };
          this.props.callLoginService(data, this.props.history);
        }
      }
    }, 300000);


 
    if (!typeof loginDetail === "object" || loginDetail === null) {
      const data = {
        failstatus: 0,
        username: SUBSCRIBER_LOGIN.username,
        password: SUBSCRIBER_LOGIN.password,
        terms: SUBSCRIBER_LOGIN.terms,
        silentLogin: true,
        internal: "Y",
      };

       this.props.callLoginService(data, this.props.history);
    } else {
      const data = {
        failstatus: 0,
        username: loginDetail.username,
        password: loginDetail.password,
        terms: loginDetail.terms,
        silentLogin: true,
        internal: loginDetail.internal,
      };
      this.props.callLoginService(data, this.props.history);
    }
  }

  render() {

     if (!this.props.logindetails.username) {
      return <></>;
    } else {
      return (
        <Router basename="/">
          <Switch>
            {/* <Route path="" component={Home} /> */}

            <Route path="/signup" component={SignUp} />
            <Route path="/signin" component={SignIn} />
            <Route path="/resetpassword" component={ResetPassword} />


            {!this.props.authed && <Redirect to="/signin" />}
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  component={route.component}
                  exact={route.exact}
                />
              );
            })}
            <Route path="*" component={Home} />
          </Switch>
        </Router>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  authed: state.SignInReducer.authed,
  logindetails: state.SignInReducer.logindetails,
});
export default connect(mapStateToProps, { callLoginService })(App);
