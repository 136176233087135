import React from "react";
import { Container, Row, Col } from "react-bootstrap";

// import carouselbackground3 from '../images/order-concept.jpg';
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { Carousel } from "antd";
import {
  GiftOutlined,
  LockOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";

import { connect } from "react-redux";
import { APPLICATION_CONST } from "../config/customConfig";

/**********************Components ***********************/
import Product from "../components/common/Product";

/**********************Components ***********************/

import { addToCart } from "../actions/public/cartAction";

const contentStyle = {
  height: "460px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  navigateToCategories = () => {
    console.log(this.props);
  };

  navigateToProductDetails = (data) => {
    this.props.history.push("/productdetails/" + data.productid, {
      product: data.product,
    });
  };

  goToCartCheck = (productid) => {
    const cart = this.props.cartItems.filter(
      (product) => product.productid === productid
    ).length;
    return cart;
  };

  _addToCart = (product) => {
    this.props.addToCart({
      ...product,
      quantityincart: 1,
    });
  };

  render() {
    const banners =[]// TODO: this.props.logindetails.banners.main;
    const topsellers = this.props.logindetails.productlists.topsellers;
 
    return (
      <>
        <Navbar {...this.props} />

        <Container fluid style={{ padding: 0 }}>
          <Carousel autoplay>
            {/* <img className="img-fluid" src={carouselbackground4} /> */}

            {banners && banners.length > 0
              ? banners.map((banner, index) => (
                  <img
                    key={index}
                    className="img-fluid"
                    src={APPLICATION_CONST.bannerImages + "" + banner.bannerid}
                  />
                ))
              : null}
          </Carousel>
        </Container>

        <Container fluid>
          <Row
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              backgroundColor: "#F1F1F1",
              border: "0px 0px 0.5px 0px solid #222",
            }}
          >
            <Col
              md={4}
              className="underCarouselSectionWrapper"
              style={{ borderWidth: 0 }}
            >
              <div
                style={{ textAlign: "center" }}
                className="underCarouselItemWrapper"
              >
                <div>
                  <GiftOutlined className="underCarouselIcon" />
                </div>
                <div>
                  <div className="underCarouselSectionHeader">
                    Free Shipping
                  </div>
                  <div>On all orders over $5000</div>
                </div>
              </div>
            </Col>
            <Col md={4} className="underCarouselSectionWrapper">
              <div
                style={{ textAlign: "center" }}
                className="underCarouselItemWrapper"
              >
                <div>
                  <LockOutlined className="underCarouselIcon" />
                </div>
                <div>
                  <div className="underCarouselSectionHeader">
                    Secure Payment
                  </div>
                  <div>100% Secure Payments</div>
                </div>
              </div>
            </Col>
            <Col md={4} className="underCarouselSectionWrapper">
              <div
                style={{ textAlign: "center" }}
                className="underCarouselItemWrapper"
              >
                <div>
                  <CustomerServiceOutlined className="underCarouselIcon" />
                </div>
                <div>
                  <div className="underCarouselSectionHeader">24/7 Support</div>
                  <div>24 Hour Customer Support</div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid style={{ backgroundColor: "#FFF" }}>
          <Row
            className="justify-content-md-center"
            style={{ paddingTop: 30, paddingBottom: 30 }}
          >
            <div style={{ fontWeight: "bold", fontSize: 30 }}>
              Today's Top Sellers
            </div>
          </Row>
          <Row>
            {topsellers && topsellers.length > 0
              ? topsellers.map((product, index) => (
                  <Product
                    key={index}
                    src={
                      APPLICATION_CONST.productImageUrl +
                      product.productreferenceid +
                      ".png" +
                      "?" +
                      new Date()
                    }
                    alreadyCartAdded={
                      this.goToCartCheck(product.productid) ? true : false
                    }
                    product={product}
                    addToCart={() => this._addToCart(product)}
                    goToCart={() => this.props.history.push("/shoppingcart")}
                    onClick={() =>
                      this.navigateToProductDetails({
                        productid: product.productid,
                        product: product,
                      })
                    }
                  />
                ))
              : null}
          </Row>
        </Container>

        {/* <Container fluid style={{ marginTop:10, marginBottom:10 }}>

                    <Row className="justify-content-md-center">
                  
                        <Col md={6} onClick={() => this.navigateToCategories()} style={{ backgroundColor:'yellow', height:400 }}>
                        </Col>
                        <Col md={6} onClick={() => this.navigateToCategories()} style={{ backgroundColor:'pink', height:400 }}>
                        </Col>
                    
                    </Row>
                </Container> */}

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authed: state.SignInReducer.authed,
  logindetails: state.SignInReducer.logindetails,
  cartItems: state.CartReducer.cart,
});
export default connect(mapStateToProps, { addToCart })(Home);
