import React, { Component } from "react";
import { Form, FormControl, Container, Row, Col, Image } from "react-bootstrap";
import BannerImage from "../images/banner2.jpg";
import image1 from "../images/1.jpg";
import image2 from "../images/2.jpg";
import image3 from "../images/3.jpg";
import image4 from "../images/4.jpg";
import image5 from "../images/5.jpg";
import image6 from "../images/6.jpg";
import laptop from "../images/laptop.jpeg";
import { Link } from "react-router-dom";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { Button, Card } from "antd";
import { CloseCircleOutlined, PrinterOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { emptyCart } from "../actions/public/cartAction";

class FailedConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    if (!this.props.cartItems.length) {
      this.props.history.push("/");
    }
  }

  cancelCart = () => {
    this.props.emptyCart();
    this.props.history.push("/");
  };
  render() {
    let statusMessage = this.props.location.state.statusMessage;

    return (
      <>
        <Navbar />
        <Container
          style={{
            paddingTop: 50,
            paddingBottom: 50,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            <CloseCircleOutlined style={{ fontSize: 50, color: "red" }} />
            <div style={{ fontSize: 20, color: "red" }}>{statusMessage}</div>
          </div>

          <Row>
            <Col md={2}></Col>
            <Col md={8}>
              <Row>
                <Col md={6}>
                  <Button
                    block
                    type="ghost"
                    onClick={() => this.props.history.push("/paymentdetails")}
                  >
                    Edit Payment Details
                  </Button>
                </Col>
                <Col md={6}>
                  <Button block type="ghost" onClick={() => this.cancelCart()}>
                    Cancel Cart
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={2}></Col>
          </Row>

          <div
            style={{ height: 1, backgroundColor: "#DDD", marginTop: 10 }}
          ></div>
        </Container>

        <Container>
          <Row>
            <Col md={3}>
              <div
                style={{ fontSize: 18, fontWeight: "bold", marginBottom: 20 }}
              >
                Delivery Details
              </div>
              <div
                style={{ fontSize: 14, fontWeight: "bold", marginBottom: 10 }}
              >
                Deliver For
              </div>
              <div>
                <div>John Brown</div>
                <div>
                  <span style={{ fontSize: 14, fontWeight: "bold" }}>
                    Phone #:
                  </span>
                  <span>(876) 873-5150</span>
                </div>
              </div>

              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                Payment Information
              </div>
              <div style={{ fontSize: 14, fontWeight: "bold" }}>
                Credit Card
              </div>
              <div>
                <div>
                  <div>
                    <span style={{ fontSize: 14, fontWeight: "300" }}>
                      Card #:
                    </span>
                    <span> ************7653</span>
                  </div>
                  <div>
                    <span style={{ fontSize: 14, fontWeight: "300" }}>
                      Expiry Date:
                    </span>
                    <span> 03/2023</span>
                  </div>
                  <div>
                    <span style={{ fontSize: 14, fontWeight: "300" }}>
                      Amount:
                    </span>
                    <span> $6000</span>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div
                style={{ fontSize: 18, fontWeight: "bold", marginBottom: 20 }}
              >
                Delivery Method
              </div>
              <div>
                <div>
                  <div>Pick up at Location 1</div>
                  <div>Address of location 1</div>
                </div>
              </div>

              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                Billing Address
              </div>
              <div>
                <div>
                  <div>
                    <span>3616 Goodwin Avenue</span>
                  </div>
                  <div>
                    <span>Kennewick</span>
                  </div>
                  <div>
                    <span>Washington</span>
                  </div>
                  <div>
                    <span>509-440-8578</span>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={6} style={{ backgroundColor: "#F1F1F1" }}>
              <div style={{ padding: 20 }}>
                <div
                  style={{ fontSize: 22, fontWeight: "bold", marginBottom: 20 }}
                >
                  Order Summary
                </div>

                <div
                  style={{ height: 1, backgroundColor: "#DDD", marginTop: 10 }}
                ></div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                >
                  <div>Item(s):</div>
                  <div style={{ fontWeight: "bold" }}>$5000.00</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                >
                  <div>Sales Tax:</div>
                  <div style={{ fontWeight: "bold" }}>$1000.00</div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                >
                  <div>Shipping:</div>
                  <div style={{ fontWeight: "bold" }}>$1000.00</div>
                </div>

                <div
                  style={{ height: 1, backgroundColor: "#DDD", marginTop: 10 }}
                ></div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 10,
                    marginTop: 20,
                  }}
                >
                  <div style={{ fontSize: 22 }}>Total:</div>
                  <div style={{ fontWeight: "bold", fontSize: 22 }}>
                    $6000.00
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div
            style={{ height: 1, backgroundColor: "#DDD", marginTop: 10 }}
          ></div>
        </Container>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.CartReducer.cart,
});
export default connect(mapStateToProps, { emptyCart })(FailedConfirmation);
