import axios from "axios";
import { APPLICATION_CONST } from "../../config/customConfig";

export const getAddresses = async (data) => {
  return new Promise((resolve, reject) => {
    const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));
    const body = {
      username: loginDetail.username,
      sessionid: loginDetail.sessionid,
      failstatus: 0,
      request: {},
    };

    

    axios({
      method: "post",
      url: `${APPLICATION_CONST.url}getaddresses`,
      data: body,
    })
      .then((response) => {
        

        if (response.data.status === 0) {
          
          resolve({
            status: response.data.status,
            statusMessage: response.data.statusMessage,
            data: response.data.data,
          });
        } else if (response.data.status > 0) {
          resolve({
            status: response.data.status,
            statusMessage: response.data.statusMessage,
            data: {},
          });
        } else {
          resolve({
            status: null,
            statusMessage: APPLICATION_CONST.message,
            data: {},
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
};
