import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';
import { Card, Select, Pagination, Button } from 'antd';

class LoginSecurity extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

   

    render() {

        return (
            <>
                <Navbar />
                    <div style={{ fontWeight:'bold', padding: 20, fontSize:25 }}>Login & Security</div>

                    <Container style={{ marginBottom: 200 }}>

                        <Row className="justify-content-center">

                            <Col md={6} style={{ borderRadius:5, border:'0.01em solid #999', padding:0, overflow:'hidden' }}>
                                <div style={{ display: 'flex', flexDirection:'row', width:'100%', alignItems:'center', justifyContent:'space-between', border:'1px solid #EEE' }}>
                                    <div style={{ padding: 20 }}>
                                        <div>Name:</div>
                                        <div>John Brown</div>
                                    </div>
                                    <div style={{ padding: 20 }}>
                                        <Button type="primary">Edit</Button>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', flexDirection:'row', width:'100%', alignItems:'center', justifyContent:'space-between', border:'1px solid #EEE' }}>
                                    <div style={{ padding: 20 }}>
                                        <div>Email:</div>
                                        <div>john@gmail.com</div>
                                    </div>
                                    <div style={{ padding: 20 }}>
                                        <Button type="primary">Edit</Button>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', flexDirection:'row', width:'100%', alignItems:'center', justifyContent:'space-between', border:'1px solid #EEE' }}>
                                    <div style={{ padding: 20 }}>
                                        <div>Phone Number:</div>
                                        <div>+1 (876) 484-3949</div>
                                    </div>
                                    <div style={{ padding: 20 }}>
                                        <Button type="primary">Edit</Button>
                                    </div>
                                </div>


                                <div style={{ display: 'flex', flexDirection:'row', width:'100%', alignItems:'center', justifyContent:'space-between', border:'1px solid #EEE' }}>
                                    <div style={{ padding: 20 }}>
                                        <div>Password</div>
                                        <div>**********</div>
                                    </div>
                                    <div style={{ padding: 20 }}>
                                        <Button type="primary">Edit</Button>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', flexDirection:'row', width:'100%', alignItems:'center', justifyContent:'space-between', border:'1px solid #EEE' }}>
                                    <div style={{ padding: 20 }}>
                                        <div>Two-Step Verification (2SV) Settings:</div>
                                        <div>Manage your Two Step Verification (2SV) Authenticators </div>
                                    </div>
                                    <div style={{ padding: 20 }}>
                                        <Button type="primary">Edit</Button>
                                    </div>
                                </div>
                                
                            </Col>

                        </Row>

                    </Container>

                <Footer />
            </>
        )
    }
  }

  
  export default LoginSecurity;
  