import React, { Component } from "react";
import { Navbar, Nav, Form, Button, FormControl, Container, Row , Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import visa from '../images/visa.png';
import mastercard from '../images/mastercard.png';
import amex from '../images/amex.png';
import discover from '../images/discover.png';
import paypal from '../images/paypal.png';
import applepay from '../images/applepay.png';
import googlewallet from '../images/googlewallet.png'
import { Input, Space } from 'antd';
import { AudioOutlined, TwitterOutlined, FacebookFilled, InstagramFilled } from '@ant-design/icons';

const { Search } = Input;

const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: '#1890ff',
    }}
  />
);

const onSearch = value => console.log(value);

class Footer extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
        };
      }

    render(){

        return (
          
        this.props.minimal === true ?


        // <footer className="footerminimal">
    
            <div style={{ display:'flex', flexDirection:'row', fontSize:11, justifyContent:'center', alignItems:'center' }}>
                <div style={{ margin: 10 }}>
                    <a href="#" style={{ color:'#111', fontWeight:'bold' }}>Terms and Conditions</a>
                </div>
                <div style={{ color:"#111", display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center' }}>
                    |
                </div>
                
                <div style={{ margin: 10 }}>
                    <a href="#" style={{ color:'#111', fontWeight:'bold' }}>Privacy</a>  
                </div>
                <div style={{ color:"#111", display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center' }}>
                    |
                </div>
                <div style={{ margin: 10 , color:'#111', fontWeight:'bold' }}>
                    © 2021 Shopper
                </div>
            </div>
     
        // </footer>
        :
            <footer className="footer">
                <Container>
                    <div className="footer-left col-md-4 col-sm-6">
                        <p className="about">
                        <span style={{ fontSize:16, fontWeight:'bold' }}>Make Money with Us</span> 
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.
                        </p>
                        <div className="icons">   
                            <img className="footerpaymenticon" src={visa} /> 
                            <img className="footerpaymenticon" src={mastercard} /> 
                            <img className="footerpaymenticon" src={amex} /> 
                            <img className="footerpaymenticon" src={discover} /> 
                            <img className="footerpaymenticon" src={paypal} /> 
                            <img className="footerpaymenticon" src={applepay} /> 
                            <img className="footerpaymenticon" src={googlewallet} /> 
                        </div>
                    </div>
                    <div className="footer-center col-md-4 col-sm-6" style={{ color:'#FFF' }}>
                        <div style={{ fontSize:16, fontWeight:'bold' }}>
                            Quick Links
                        </div>
                        <ul style={{ fontSize:11, padding:0, margin:15, listStyle:'none' }}>
                            <li><a href="#" style={{ color:'#FFF', lineHeight:2 }}>About Us</a></li>
                            <li><a href="#" style={{ color:'#FFF', lineHeight:2 }}>FAQ</a></li>
                            <li><a href="#" style={{ color:'#FFF', lineHeight:2 }}>Help</a></li>
                            <li><a href="#" style={{ color:'#FFF', lineHeight:2 }}>My Account</a></li>                                        
                        </ul>
                    </div>

                    <div className="footer-center col-md-4 col-sm-6">
                        <div>
                            <div style={{ color:'#FFF', fontWeight:'bold', fontSize:16, marginBottom: 10 }}>
                                Keep in Touch
                            </div>
                            <Search
                                placeholder="Your Email"
                                allowClear
                                enterButton=">>"
                                onSearch={onSearch}
                            />
                        </div> 
                        <div>
                            <div style={{ color:'#FFF', fontWeight:'bold', fontSize:16, marginTop:20, marginBottom: 10 }}>
                                Follow Us
                            </div>
                            <div style={{ fontSize:25 }}>   
                                <a href="#" style={{ color:'#FFF' }}><FacebookFilled style={{ marginRight: 15 }} /></a>
                                <a href="#" style={{ color:'#FFF' }}><TwitterOutlined style={{ marginRight: 15 }} /></a>
                                <a href="#" style={{ color:'#FFF' }}><InstagramFilled style={{ marginRight: 15 }} /></a>
                            </div>
                        </div>
                    </div>
        
                </Container>

                <Container>
                    <Row>

                        <Col md={12} style={{ display:'flex', fontSize:11, justifyContent:'flex-end' }}>
                            <div style={{ display:'flex', flexDirection:'row', fontSize:11,   }}>
                                <div style={{ margin: 10 }}>
                                    <a href="#" style={{ color:'#FFF' }}>Terms and Conditions</a>
                                </div>
                                <div style={{ color:"#FFF", display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center' }}>
                                    |
                                </div>
                                
                                <div style={{ margin: 10 }}>
                                    <a href="#" style={{ color:'#FFF' }}>Privacy</a>  
                                </div>
                                <div style={{ color:"#FFF", display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center' }}>
                                    |
                                </div>
                                <div style={{ margin: 10 , color:'#FFF' }}>
                                    © 2021 Shopper
                                </div>
                            </div>
                        </Col>
                        
                    </Row>
                </Container>

            </footer>
        )
    }

}

export default Footer