import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { Card, Breadcrumb } from "antd";
import { connect } from "react-redux";
import { categoryImageUrl } from "../config/customConfig";
import { Link } from "react-router-dom";
import { APPLICATION_CONST } from "../config/customConfig";

class Categories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  navigateToProducts = (categoryid) => {
    this.props.history.push("/products/" + categoryid);
  };

  filterCategorys = (productlists) => {
    // let productlists = this.props.productlists.allproducts;
    let tempCategorys = [];

    productlists.map((product, index) => {
      let categoryid = product.categoryid;
      let categoryname = product.categoryname;

      let obj = tempCategorys.find((o) => o.categoryid === categoryid);

      if (!obj) {
        tempCategorys.push({
          categoryid: categoryid,
          categoryname: categoryname,
        });
      }
    });

    return tempCategorys;
  };
  render() {
    return (
      <>
        <Navbar />

        <Breadcrumb style={{ margin: 20, fontSize: 12 }} separator=">">
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/Categories">Categories</Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <div style={{ fontWeight: "bold", padding: 20, fontSize: 25 }}>
          Categories
        </div>

        <Container fluid>
          <Row>
            {this.filterCategorys(this.props.productlists.allproducts).map(
              (product, index) => (
                <Col key={index} md={3}>
                  <Card
                    onClick={() => this.navigateToProducts(product.categoryid)}
                    hoverable
                    cover={
                      <img
                        alt="example"
                        src={
                          APPLICATION_CONST.categoryImageUrl +
                          product.categoryid +
                          ".png" +
                          "?" +
                          new Date()
                        }
                      />
                    }
                  >
                    {/* <Meta title="Europe Street beat" description="www.instagram.com" /> */}
                    <div>{product.categoryname}</div>
                  </Card>
                </Col>
              )
            )}
          </Row>
        </Container>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  productlists: state.SignInReducer.logindetails.productlists,
});
export default connect(mapStateToProps, {})(Categories);
