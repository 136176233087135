import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';
import { Card, Select, Pagination } from 'antd';

class Account extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    navigateToProductDetails = () => {
        this.props.history.push('/productdetails')
    }

    render() {

        return (
            <>
                <Navbar />
                    <div style={{ fontWeight:'bold', padding: 20, fontSize:25 }}>My Account</div>

                    <Container style={{ marginBottom: 200 }}>

                        <Row style={{ marginTop: 30, marginBottom: 30 }}>

                            {/* <Col md={4}>
                                <a href="/profile">

                                    <div style={{ padding: 20, minHeight:150, backgroundColor:'#F1F1F1', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', border:'0.01em solid #E5E5E5', fontSize: 20, fontWeight:'bold' }}>
                                        <div>Your Profile</div>
                                    </div>
                                </a>
                            </Col> */}
                            <Col md={4} >
                                <a href="/orders">
                                    <div style={{ padding: 20, minHeight:150, backgroundColor:'#F1F1F1', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', border:'0.01em solid #E5E5E5', fontSize: 20, fontWeight:'bold' }}>
                                        <div>Your Orders</div>
                                    </div>
                                </a>
                            </Col>
                            <Col md={4}>
                                <a href="/loginsecurity">

                                    <div style={{ padding: 20, minHeight:150, backgroundColor:'#F1F1F1', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', border:'0.01em solid #E5E5E5', fontSize: 20, fontWeight:'bold' }}>
                                        <div>Login & Security</div>
                                    </div>
                                </a>   
                            </Col>

                           
                        </Row>

                    </Container>

                <Footer />
            </>
        )
    }
  }

  
  export default Account;
  