import React, { Component } from "react";
import {
  Form,
  FormControl,
  Container,
  Row,
  Col,
  Image,
  Card,
} from "react-bootstrap";

import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { Input, Button, Breadcrumb } from "antd";

import { DeleteOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { APPLICATION_CONST } from "../config/customConfig";
import {
  changeProductQuantity,
  removeItem,
  setIsEdit,
} from "../actions/public/cartAction";
class ShoppingCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promoCode: "",
    };
  }

  navigateToCategories = () => {
    this.props.history.push("/categories");
  };

  navigateToPaymentDetails = () => {
    this.props.history.push("/paymentdetails");
  };

  navigateToPreCheckout = () => {
    const signInDetail = JSON.parse(localStorage.getItem("loginDetail"));

    if (signInDetail) {
      if (signInDetail.internal === "Y") {
        this.props.history.push("/signin",{
          redirectRoute: "/paymentdetails",
          silentLogin: true
        });
      } else {
        this.props.history.push("/paymentdetails");
      }
    }
  };

  _changeProductQuantity = (productid, quantity, maxquantity) => {
    if (quantity > maxquantity) {
      alert(`Only ${maxquantity} item left in stock`);
    } else {
      if (quantity >= 1) {
        this.props.changeProductQuantity(productid, quantity, maxquantity);
      }
    }
  };

  _removeItmeConfirm = (product) => {
    this.setState({
      displayToast: true,
      toastMessage: `${product.productname} deleted from cart.`,
    });
    this.props.removeItem(product);
  };

  removeItemFromCart = (product) => {
    this._removeItmeConfirm(product);
  };

  render() {
    
    return (
      <>
        <Navbar />

        <Breadcrumb style={{ margin: 20, fontSize: 12 }} separator=">">
          <Breadcrumb.Item>
            <a href="/">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="">Shopping Cart</a>
          </Breadcrumb.Item>
        </Breadcrumb>

        <div style={{ fontWeight: "bold", padding: 20, fontSize: 25 }}>
          Shopping Cart
        </div>
        {this.props.cartItems.length > 0 ? (
          <>
            <Container
              style={{
                marginTop: 10,
                marginBottom: 10,
                paddingTop: 10,
                paddingBottom: 10,
                borderTopWidth: 0.5,
                borderBottomWidth: 0.5,
                borderColor: "#CCC",
              }}
            >
              <Row
                className="justify-content-md-center"
                style={{ padding: 10 }}
              >
                <Col md={5} style={{ padding: 0 }}>
                  <div style={{ fontWeight: "400", fontSize: 15 }}>PRODUCT</div>
                </Col>
                <Col
                  md={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "400", fontSize: 15 }}>PRICE</div>
                </Col>
                <Col
                  md={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "400", fontSize: 15 }}>
                    QUANTITY
                  </div>
                </Col>
                <Col
                  md={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "400", fontSize: 15 }}>
                    SUBTOTAL
                  </div>
                </Col>
                <Col md={1}></Col>
              </Row>
            </Container>

            <Container style={{ marginTop: 10, marginBottom: 10 }}>
              {this.props.cartItems && this.props.cartItems.length > 0
                ? this.props.cartItems.map((product, index) => (
                    <Row
                      key={index}
                      style={{
                        backgroundColor: "#F1F1F1",
                        padding: 5,
                        marginTop: 10,
                      }}
                    >
                      <Col md={2} style={{ padding: 0 }}>
                        <img
                          src={
                            APPLICATION_CONST.productImageUrl +
                            product.productreferenceid +
                            ".png" +
                            "?" +
                            new Date()
                          }
                          style={{ width: 80, height: "auto" }}
                        />
                      </Col>
                      <Col
                        md={2}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ textAlign: "left" }}>
                          {product.productname}
                        </div>
                      </Col>
                      <Col
                        md={2}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ textAlign: "right" }}>
                          ${product.unitcost}
                        </div>
                      </Col>
                      <Col
                        md={2}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Button
                            type="text"
                            disabled={product.quantityincart === 1}
                            onClick={() =>
                              this._changeProductQuantity(
                                product.productid,
                                product.quantityincart - 1,
                                product.quantity
                              )
                            }
                          >
                            -
                          </Button>
                          <Input
                            disabled
                            placeholder="1"
                            value={product.quantityincart}
                            style={{ textAlign: "center" }}
                          />
                          <Button
                            type="text"
                            onClick={() =>
                              this._changeProductQuantity(
                                product.productid,
                                product.quantityincart + 1,
                                product.quantity
                              )
                            }
                          >
                            +
                          </Button>
                        </div>
                      </Col>
                      <Col
                        md={2}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>${product.unitcost * product.quantityincart}</div>
                      </Col>

                      <Col
                        md={1}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <DeleteOutlined
                          onClick={() => this.removeItemFromCart(product)}
                          style={{ fontSize: 16, color: "red" }}
                        />
                      </Col>
                    </Row>
                  ))
                : null}
            </Container>

            <Container fluid style={{ marginTop: 10, marginBottom: 10 }}>
              <Row>
                <Col md={9}>
                  <div style={{ display: "flex", width: 500, marginLeft: 15 }}>
                    <Input
                      placeholder="Promo Code"
                      style={{ textAlign: "center", marginRight: 10 }}
                      value={this.state.promoCode}
                      onChange={(evt) =>
                        this.setState({
                          promoCode: evt.target.value,
                        })
                      }
                    />
                    <Button
                      type="primary"
                      block
                      onClick={() => this.navigateToCategories()}
                    >
                      Apply Promo Code
                    </Button>
                  </div>
                </Col>

                <Col md={3}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 20,
                      marginRight: 15,
                    }}
                  >
                    <div style={{ fontWeight: "bold", fontSize: 20 }}>
                      Total
                    </div>
                    <div style={{ fontWeight: "bold", fontSize: 20 }}>
                      ${this.props.cartTotal}
                    </div>
                  </div>
                  <Button
                    type="primary"
                    style={{ marginTop: 5 }}
                    block
                    onClick={() => this.navigateToCategories()}
                  >
                    Continue Shopping
                  </Button>

                  <Button
                    type="primary"
                    style={{ marginTop: 5 }}
                    block
                    onClick={() => this.navigateToPreCheckout()}
                  >
                    Proceed to Checkout
                  </Button>
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          <Container
            fluid
            style={{
              marginTop: 10,
              marginBottom: 10,
              justifyContent: "center",
            }}
            className="text-center"
          >
            <img
              src={APPLICATION_CONST.emptyCartImage}
              height="200"
              alt="empty-cart"
            />
            <h3>Your cart is empty!</h3>
            <p>Looks like you haven't made your choice yet</p>
          </Container>
        )}

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authed: state.SignInReducer.authed,
  logindetails: state.SignInReducer.logindetails,
  cartItems: state.CartReducer.cart,
  cartTotal: state.CartReducer.cartTotal,
});
export default connect(mapStateToProps, { changeProductQuantity, removeItem })(
  ShoppingCart
);
