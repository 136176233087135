import React, { Component } from "react";
import { APPLICATION_CONST } from "../config/customConfig";

import "bootstrap/dist/css/bootstrap.min.css";
import Product from "./common/Product";

import { Container, Row, Col, Image } from "react-bootstrap";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { Card, Select, Pagination, Button } from "antd";

import { addToCart } from "../actions/public/cartAction";
import { connect } from "react-redux";

const { Option } = Select;
/**********************Components ***********************/

/**********************Components ***********************/

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  navigateToProductDetails = (data) => {
    this.props.history.push("/productdetails/" + data.productid, {
      product: data.product,
    });
  };

  totalProducts = () => {
    const categoryId = this.props.match.params.categoryId;

    const productlists = this.props.logindetails.productlists;

    let products;

    if (categoryId) {
      if (categoryId === "deals") {
        products = productlists.deals;
      } else if (categoryId === "todaydeals") {
        products = productlists.todaydeals;
      } else {
        products = productlists.allproducts.filter(
          (o) => o.categoryid === categoryId
        );
      }
    } else {
      products = productlists.allproducts;
    }

    return products;
  };
  filterProducts = () => {
    const categoryId = this.props.match.params.categoryId;

    const productlists = this.props.logindetails.productlists;

    let products;

    if (categoryId) {
      if (categoryId === "deals") {
        products = productlists.deals;
      } else if (categoryId === "todaydeals") {
        products = productlists.todaydeals;
      } else {
        products = productlists.allproducts.filter(
          (o) => o.categoryid === categoryId
        );
      }
    } else {
      products = productlists.allproducts;
    }

    return products;
  };

  goToCartCheck = (productid) => {
    const cart = this.props.cartItems.filter(
      (product) => product.productid === productid
    ).length;
    return cart;
  };

  _addToCart = (product) => {
    this.props.addToCart({
      ...product,
      quantityincart: 1,
    });
  };

  filterCategorys = () => {
    let productlists = this.props.productlists.allproducts;
    let tempCategorys = [];

    productlists.map((product, index) => {
      let categoryid = product.categoryid;
      let categoryname = product.categoryname;

      let obj = tempCategorys.find((o) => o.categoryid === categoryid);

      if (!obj) {
        tempCategorys.push({
          categoryid: categoryid,
          categoryname: categoryname,
        });
      }
    });

    return tempCategorys;
  };

  categoryNameByCategoryId = () => {
    let categorys = this.filterCategorys();
    const categoryId = this.props.match.params.categoryId;
    let category;
    if (categoryId) {
      if (categoryId === "deals") {
        category = "Deals";
      } else if (categoryId === "todaydeals") {
        category = "Today Deals";
      } else {
        let findCategory = categorys.find((o) => o.categoryid === categoryId);
        category = findCategory ? findCategory.categoryname : "";
      }
    } else {
      category = "";
    }

    return category;
  };

  countProducts = () => {
    return parseFloat(this.totalProducts().length);
  };

  render() {
    return (
      <>
        <Navbar />
        <div style={{ fontWeight: "bold", padding: 20, fontSize: 25 }}>
          {this.categoryNameByCategoryId()}
        </div>

        <Container fluid>
          <Row
            style={{
              padding: 15,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>Showing All {this.filterProducts().length} Results</div>
            <div>
              <span style={{ marginRight: 10 }}>Sort By</span>
              <Select defaultValue="1" style={{ width: 220 }}>
                <Option value="1">Price High to Low</Option>
                <Option value="2">Price Low to High</Option>
                <Option value="3">Size</Option>
                <Option value="4">Name A-Z</Option>
                <Option value="5">Name Z-A</Option>
                <Option value="6">Ratings</Option>
              </Select>
            </div>
          </Row>
          <Row className="justify-content-md-center">
            {this.filterProducts() && this.filterProducts().length > 0
              ? this.filterProducts().map((product, index) => (
                  <Product
                    key={index}
                    src={
                      APPLICATION_CONST.productImageUrl +
                      product.productreferenceid +
                      ".png" +
                      "?" +
                      new Date()
                    }
                    product={product}
                    alreadyCartAdded={
                      this.goToCartCheck(product.productid) ? true : false
                    }
                    product={product}
                    addToCart={() => this._addToCart(product)}
                    goToCart={() => this.props.history.push("/shoppingcart")}
                    onClick={() =>
                      this.navigateToProductDetails({
                        productid: product.productid,
                        product: product,
                      })
                    }
                  />
                ))
              : null}
          </Row>

          <Row>
            <Col
              md={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <Pagination defaultCurrent={1} total={this.countProducts()} />
            </Col>
          </Row>
        </Container>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authed: state.SignInReducer.authed,
  logindetails: state.SignInReducer.logindetails,
  cartItems: state.CartReducer.cart,
  productlists: state.SignInReducer.logindetails.productlists,
});
export default connect(mapStateToProps, { addToCart })(Products);
