import React, { Component } from "react";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import BannerImage from "../images/banner2.jpg";
import shoes1 from "../images/shoes1.jpg";
import shoes2 from "../images/shoes2.jpg";
import shoes3 from "../images/shoes3.jpg";
import { Link } from "react-router-dom";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { Rate, Select, Button, Input, Form, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { resetPasswordSubmit } from "../actions/authentication/resetPasswordAction";
import { connect } from "react-redux";
import md5 from "md5";
import DialogModal from "../common/dialogModal";
import { styles } from "../styles/commontStyles";
import { callChangePasswordService } from "../actions/authentication/changePasswordAction";

const onFinish = (values) => {
  console.log("Received values of form: ", values);
};

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      oldpassword: "",
      newpassword: "",
      confirmpassword: "",
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
    };

    const signInDetail = JSON.parse(localStorage.getItem("loginDetail"));

    if (signInDetail) {
      if (signInDetail.internal === "N") {
        this.props.history.push("/");
      }
    }    
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== this.props.error && nextProps.error !== null) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: nextProps.error,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    }
  }

  handleLogin = async () => {
    let username = this.props.logindetails.username;
    let oldpassword = this.state.oldpassword;
    let newpassword = this.state.newpassword;
    let confirmpassword = this.state.confirmpassword;

    if (username.trim() === "") {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: "Username is required",
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    } else if (oldpassword.trim() === "") {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: "Old Password is required",
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    } else if (newpassword.trim() === "") {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: "New Password is required",
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    } else if (newpassword.trim() !== confirmpassword.trim()) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: "Confirm Password not matched",
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    } else {
      // if (username.value !== '') {
      const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

      const data = {
        username: loginDetail.username,
        sessionid: loginDetail.sessionid,
        oldPassword: oldpassword.trim(),
        newPassword: newpassword.trim(),
      };

      let response = await callChangePasswordService(data, this.props.history);
      

      if (response.success) {
        this.setState({
          displayDialog: true,
          dialogTitle: "Success!",
          dialogBody: response.statusMessage,
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.closeDialog}
            >
              Close
            </button>
          ),
        });
      } else {
        this.setState({
          displayDialog: true,
          dialogTitle: "Oops!",
          dialogBody: response.statusMessage,
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.closeDialog}
            >
              Close
            </button>
          ),
        });
      }
    }
    // this.props.history.push("/paymentdetails");
  };

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  render() {
    return (
      <>
        <Navbar minimal />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />
        <div
          style={{
            fontWeight: "bold",
            fontSize: 26,
            textAlign: "center",
            marginTop: 50,
          }}
        >
          SHOPPER
        </div>

        <div
          style={{
            fontWeight: "bold",
            fontSize: 16,
            textAlign: "center",
            marginTop: 50,
          }}
        >
          Change Password
        </div>
        <Container
          fluid
          style={{
            marginTop: 10,
            marginBottom: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row>
            <div style={{ width: 300 }}>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  name="oldpassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your old password!",
                    },
                  ]}
                >
                  <Input
                    // disabled
                    type="password"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Old Password"
                    value={this.state.oldpassword}
                    onChange={(evt) =>
                      this.setState({
                        oldpassword: evt.target.value,
                      })
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="newpassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your new password!",
                    },
                  ]}
                >
                  <Input
                    // disabled
                    type="password"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="New Password"
                    value={this.state.newpassword}
                    onChange={(evt) =>
                      this.setState({
                        newpassword: evt.target.value,
                      })
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="confirmpassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your confirm password!",
                    },
                  ]}
                >
                  <Input
                    // disabled
                    type="password"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Confirm Password"
                    value={this.state.confirmpassword}
                    onChange={(evt) =>
                      this.setState({
                        confirmpassword: evt.target.value,
                      })
                    }
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    onClick={() => this.handleLogin()}
                  >
                    Change Password
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Row>
          <Row>
            <Col md={12}>
              <div style={{ fontWeight: "bold" }}>
                Back To <Link to="/signin"> Sign In</Link>
              </div>
            </Col>
          </Row>
        </Container>

        <Footer minimal />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authed: state.SignInReducer.authed,
  logindetails: state.SignInReducer.logindetails,
  error: state.SignInReducer.error,
});
export default connect(mapStateToProps, {})(ChangePassword);
