import React from "react";
import { Container, Row, } from "react-bootstrap";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { Button, Input, Form } from "antd";
import { UserOutlined } from "@ant-design/icons";
import DialogModal from "../common/dialogModal";
import { styles } from "../styles/commontStyles";
import { signupService } from "../actions/authentication/signUpAction";
import { Link } from "react-router-dom";


// TODO: Should implement redirectRoute machanism  in signup

class SignUp extends React.Component {

  usernameInputRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      firstname: "",
      lastname: "",
      phonenumber: "",
      email: "",
      signingUp: false,
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
    };
  }

  componentDidMount(){
    this.usernameInputRef.current.focus();
  }

  handelSubmit = async () => {
    let username = this.state.username;
    let firstname = this.state.firstname;
    let lastname = this.state.lastname;
    let email = this.state.email;
    let phone = this.state.phonenumber;
 if (
      username !== "" &&
      firstname !== "" &&
      lastname !== "" &&
      email !== "" &&
      phone !== ""
    ) {
      this.setState({signingUp: true});
      const data = {
        username: username,
        firstname: firstname,
        lastname: lastname,
        email: email,
        phone: phone,
      };

      let response = await  signupService(data, this.props.history);
      this.setState({signingUp: false});
      if(response.success)
      {
        this.setState({
            displayDialog: true,
            dialogTitle: "Success!",
            dialogBody: "You have signed up successfully. Please login to continue.",  //response.statusMessage,
            dialogFooter: (
              <button
                className="btn"
                style={styles.dialogCancelButtonStyle}
                onClick={this.closeDialog}
              >
                Close
              </button>
            ),
          });
      }
      else
      {
        this.setState({
            displayDialog: true,
            dialogTitle: "Oops!",
            dialogBody: response.statusMessage,
            dialogFooter: (
              <button
                autoFocus
                className="btn"
                style={styles.dialogCancelButtonStyle}
                onClick={this.closeDialog}
              >
                Close
              </button>
            ),
          });
      }
    }
  };

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
    this.props.history.push('/signin');
  };

  render() {
    return (
      <>
        <Navbar minimal />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />
        <div
          style={{
            fontWeight: "bold",
            fontSize: 16,
            textAlign: "center",
            marginTop: 50,
          }}
        >
          Sign Up
        </div>
        <Container
          fluid
          style={{
            marginTop: 10,
            marginBottom: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row>
            <div style={{ width: 300 }}>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={this.handelSubmit}
              >
                <Form.Item
                  name="username"
                  rules={[
                    { required: true, message: "Please input your Username!" },
                  ]}
                >
                  <Input
                    ref={this.usernameInputRef}
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Enter username"
                    value={this.state.username}
                    onChange={(evt) =>
                      this.setState({
                        username: evt.target.value,
                      })
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your First Name!",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Enter first name"
                    value={this.state.firstname}
                    onChange={(evt) =>
                      this.setState({
                        firstname: evt.target.value,
                      })
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Last Name!",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Enter last name"
                    value={this.state.lastname}
                    onChange={(evt) =>
                      this.setState({
                        lastname: evt.target.value,
                      })
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Enter phone number"
                    value={this.state.phonenumber}
                    onChange={(evt) =>
                      this.setState({
                        phonenumber: evt.target.value,
                      })
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please enter a valid email"
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Enter email address"
                    value={this.state.email}
                    onChange={(evt) =>
                      this.setState({
                        email: evt.target.value,
                      })
                    }
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={this.state.signingUp}
                  >
                    { this.state.signingUp? "Please wait...": "Sign Up" }
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Row>

          <Row>
                        
                            <div style={{ fontWeight: "bold" }}>
                                Already have an account? <Link to="/signin">Sign in</Link>
                            </div>
                    
                    </Row>
        </Container>

        <Footer minimal />
      </>
    );
  }
}

export default SignUp;
