import React from "react";
import { Col } from "react-bootstrap";
import { Button } from "antd";

const Product = (props) => {
  
  const { onClick, src, product, addToCart, alreadyCartAdded, goToCart } =
    props;
    return (
    <Col md={2}>
      <div style={{cursor:"pointer"}}>
        <img
          onClick={() => onClick()}
          src={src}
          alt=""
          style={{ width: "100%", height: "auto" }}
        />
        <div style={{ textAlign: "center", marginTop: 10 }}>
          {alreadyCartAdded ? (
            <Button
              onClick={() => goToCart()}
              style={{ fontSize: 10 }}
              type="dashed"
            >
              Go To Cart
            </Button>
          ) : (
            <Button
              onClick={() => addToCart()}
              style={{ fontSize: 10 }}
              type="dashed"
            >
              Add To Cart
            </Button>
          )}

          <div style={{ fontWeight: "bold" }} onClick={() => onClick()}>
            {product.productname}
          </div>
          <div style={{ color: "#03A9F4" }}>${product.msrp}</div>
        </div>
      </div>
    </Col>
  );
};

export default Product;
