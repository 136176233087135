import React, { Component } from "react";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import BannerImage from "../images/banner2.jpg";
import shoes1 from "../images/shoes1.jpg";
import shoes2 from "../images/shoes2.jpg";
import shoes3 from "../images/shoes3.jpg";
import { Link } from "react-router-dom";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { Rate, Select, Button, Input, Form, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { resetPasswordSubmit } from "../actions/authentication/resetPasswordAction";
import { connect } from "react-redux";
import md5 from "md5";
import DialogModal from "../common/dialogModal";
import { styles } from "../styles/commontStyles";

const onFinish = (values: any) => {
  console.log("Received values of form: ", values);
};

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
    };

    const signInDetail = JSON.parse(localStorage.getItem("loginDetail"));

    if (signInDetail) {
      if (signInDetail.internal === "N") {
        this.props.history.push("/");
      }
    }    
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== this.props.error && nextProps.error !== null) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: nextProps.error,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    }
  }

  handleLogin = async () => {
    let username = this.state.username;
    let password = this.state.password;

    // this.props.history.push("/paymentdetails");
    if (username === "") {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: "Username is required",
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    } else {
      let response = await resetPasswordSubmit({ username: username });

      if(response.success)
      {
        this.setState({
            displayDialog: true,
            dialogTitle: "Success!",
            dialogBody: response.statusMessage,
            dialogFooter: (
              <button
                className="btn"
                style={styles.dialogCancelButtonStyle}
                onClick={this.closeDialog}
              >
                Close
              </button>
            ),
          });
      }
      else
      {
        this.setState({
            displayDialog: true,
            dialogTitle: "Oops!",
            dialogBody: response.statusMessage,
            dialogFooter: (
              <button
                className="btn"
                style={styles.dialogCancelButtonStyle}
                onClick={this.closeDialog}
              >
                Close
              </button>
            ),
          });
      }      
    }
  };

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  render() {
    return (
      <>
        <Navbar minimal />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />
        <div
          style={{
            fontWeight: "bold",
            fontSize: 26,
            textAlign: "center",
            marginTop: 50,
          }}
        >
          SHOPPER
        </div>

        <div
          style={{
            fontWeight: "bold",
            fontSize: 16,
            textAlign: "center",
            marginTop: 50,
          }}
        >
          Reset Password
        </div>
        <Container
          fluid
          style={{
            marginTop: 10,
            marginBottom: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row>
            <div style={{ width: 300 }}>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  name="username"
                  rules={[
                    { required: true, message: "Please input your Username!" },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Username"
                    value={this.state.username}
                    onChange={(evt) =>
                      this.setState({
                        username: evt.target.value,
                      })
                    }
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    onClick={() => this.handleLogin()}
                  >
                    Reset Password
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Row>
          <Row>
            <Col md={12}>
              <div style={{ fontWeight: "bold" }}>
                Back To <Link to="/signin"> Sign In</Link>
              </div>
            </Col>
          </Row>
        </Container>

        <Footer minimal />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authed: state.SignInReducer.authed,
  logindetails: state.SignInReducer.logindetails,
  error: state.SignInReducer.error,
});
export default connect(mapStateToProps, {})(ResetPassword);
