import { APPLICATION_CONST } from "../../config/customConfig";
import axios from "axios";

export const signupService = (data, history) => {
  return new Promise((resolve, reject) => {
    let body = {
      username: APPLICATION_CONST.username,
      sessionid: APPLICATION_CONST.sessionid,
      failstatus: 0,
      request: {
        ...data,
        adminuser: "N",
        subscriber: "Y",
        reportagent: "N",
      },
    };

    axios({
      method: "post",
      url: APPLICATION_CONST.url + "adduser",
      data: body,
    })
      .then((response) => {
        let resoponseData = response.data;

        if (resoponseData.status === 0) {
          resolve({
            success: true,
            statusMessage: resoponseData.statusMessage,
            data: resoponseData.data,
          });
        } else if (resoponseData.status > 0) {
          resolve({
            success: false,
            statusMessage: resoponseData.statusMessage,
            data: {},
          });
        } else {
          resolve({
            success: false,
            statusMessage: "Something went wrong",
            data: {},
          });
        }
      })
      .catch((error) => {
        resolve({
          success: false,
          statusMessage: error.message,
          data: {},
        });
      });
  });
};
