import axios from "axios";
import { APPLICATION_CONST } from "../../config/customConfig";

export const applyCreditCard = (data) => {
  return new Promise((resolve, reject) => {
    const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

    const body = {
      username: loginDetail.username,
      sessionid: loginDetail.sessionid,
      failstatus: 0,
      request: data,
    };  


    

     axios({
      method: "post",
      url: `${APPLICATION_CONST.url}applycreditcard`,
      data: body,
    })
      .then((response) => {
        let resoponseData = response.data;
    

         if (resoponseData.status === 0) {
           resolve({
            success: true,
            statusMessage: resoponseData.statusMessage,
            data: resoponseData.data,
          });
        } else if (resoponseData.status > 0) {
          resolve({
            success: false,
            statusMessage: resoponseData.statusMessage,
            data: {},
          });
        } else {
          resolve({
            success: false,
            statusMessage: "Something went wrong",
            data: {},
          });
        }
      })
      .catch((error) => {
        resolve({
          success: false,
          statusMessage: "Error",
          data: {},
        });
      });
  });
};
