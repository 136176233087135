import React, { Component } from "react";
import { connect } from "react-redux";
 
class NoMatch extends Component {
  render() {
    return (
      <>
   
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ marginTop: 50 }} className="text-center">
              <h2 style={{ marginTop: 30 }}>Oops! Page not found</h2>
            </div>
          </div>
        </div>
   
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userDetail: state.SignInReducer.logindetails,
});

export default connect(mapStateToProps)(NoMatch);
