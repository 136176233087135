import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";

import SignInReducer from "../reducers/authentication/sinInReducer";
import CartReducer from "../reducers/public/cartReducer";
import LoaderReducer from "../reducers/vendor/loaderReducer";

const saveToLocalStorage = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem("state", serializedState);
    } catch (e) {
        console.log(e);
    }
};

const loadFromLocalStorage = () => {
    try {
        const serializedState = sessionStorage.getItem("state");
        if (serializedState === null) return undefined;
        return JSON.parse(serializedState);
    } catch (e) {
        console.log(e);
    }
};

const AppReducers = combineReducers({
    SignInReducer,
    CartReducer,
    LoaderReducer,
});

const rootReducer = (state, action) => {
    return AppReducers(state, action);
};

const persistedStore = loadFromLocalStorage();
// TODO: redux dev tool extension
let store = createStore(
    rootReducer,
    persistedStore,
    compose(
        applyMiddleware(thunk),
        window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : (f) => f
    )
);
// let store = createStore(rootReducer, persistedStore, compose(applyMiddleware(thunk)));

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
