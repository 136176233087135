import React from "react";
import { Container, Row, Col, Collapse as BSCollapse } from "react-bootstrap";
import { connect } from "react-redux";
import paypal from "../images/paypal.png";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import {
    Collapse,
    Tabs,
    Input,
    Select,
    Button,
    Avatar,
    Breadcrumb,
    Modal,
    Checkbox,
    Spin,
    Popconfirm,
} from "antd";
import { CheckCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { getAddresses } from "../actions/public/getAddresses";
import { applyAddress } from "../actions/public/applyAddress";
import { confirmCart } from "../actions/public/confirmCart";
import { styles } from "../styles/commontStyles";

import { emptyCart } from "../actions/public/cartAction";
import Fac from "../plugins/fac";
import { processCard } from "../actions/common/ProcessCardAction";
import { processTransaction } from "../actions/common/OrderAction";
import { loaderStatus } from "../actions/vendor/loaderAction";
import Loader from "../components/common/loader";
import { callLoginService } from "../actions/authentication/signInAction";
import DialogModal from "../common/dialogModal";
import { applyCreditCard } from "../actions/common/applyCreditCard";
import { getCreditCards } from "../actions/common/getCreditCards";
import { getCardType, CardTypes } from "../plugins/card-types";

const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Option } = Select;

class PaymentDetails extends React.Component {
    iFrameRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            showAddAddressForm: false,
            showAddCreditCardForm: false,
            addressmodalvisible: false,
            addresses: [],
            firstNameFocus: false,
            address: {
                actiontype: "",
                sequenceid: 0,
                firstname: "",
                lastname: "",
                country: "",
                address1: "",
                address2: "",
                address3: "",
                email: "",
                phone: "",
                phone: "",
                status: "A",
            },
            carddetails: {
                cardholder: "",
                cardnumber: "",
                expirymonth: "",
                expiryyear: "",
                cvv: "",
                tokenid: "",
                cardtype: "",
                cardtypeimg: null,
            },
            checkoutAddress: {
                address1: "",
                address2: "",
                address3: "",
                countrycode: "",
                email: "",
                firstname: "",
                lastname: "",
                phone: "",
                sequenceid: "",
                status: "",
            },
            saveCard: false,
            displayDialog: false,
            dialogTitle: null,
            dialogBody: null,
            dialogFooter: null,
            creditcards: [],
            creditcardmodalvisible: false,
            addcarddetails: {
                cardholder: "",
                cardnumber: "",
                expirymonth: "",
                expiryyear: "",
                cvv: "",
                address1: "",
                address2: "",
                address3: "",
                status: "A",
                tokenid: "",
                phone: "",
                sequenceid: 0,
                actiontype: "",
                cardtype:"",
                cardtypeimg:null
            },
            showPaymentModal: false,
            formHtml: "",
            iframeLoading: true,
            paymentWaiterInterval: null,
        };

        if (!this.props.cartItems.length) {
            this.props.history.push("/");
        }
    }

    closeDialog = () => {
        this.setState({
            displayDialog: false,
        });
    };

    _callGetAddress = async () => {
        let addresses = await getAddresses();
        if (addresses.status === 0) {
            let tempAddresses = addresses.data.addresses.filter(
                (address) => address.status === "A"
            );

            this.setState({
                addresses: tempAddresses,
            });

            if (tempAddresses.length > 0) {
                if (!this.state.checkoutAddress.sequenceid) {
                    this.setState({
                        checkoutAddress: tempAddresses[0],
                    });
                }
            }
        }
    };

    _callGetCreditCards = async () => {
        let creditCards = await getCreditCards();

        if (creditCards.success && creditCards.data.creditcards.length) {
            let selectedCard = creditCards.data.creditcards.filter(
                (c) => c.status === "A"
            )[0];
            this.setState({
                creditcards: creditCards.data.creditcards.filter(
                    (c) => c.status === "A"
                ),
            });
            if (selectedCard) {

                let type = CardTypes.find(c=> c.name.toLowerCase() === selectedCard.cardtype.toLowerCase());
                this.setState({
                    carddetails: {
                        ...selectedCard,
                        expirymonth: selectedCard.expirydate.substr(-2),
                        expiryyear: selectedCard.expirydate.substr(0, 4),
                        cardnumber: "xxxxxxxxxxxx" + selectedCard.cardnumber,
                        cardtypeimg: type?.logo
                    },
                });

            }else {
                this.setState({
                    carddetails: {
                        cardholder: "",
                cardnumber: "",
                expirymonth: "",
                expiryyear: "",
                cvv: "",
                tokenid: "",
                cardtype: "",
                cardtypeimg: null,
                    },
                });
            }
        }
    };

    componentDidMount = () => {
        /************GET ADDRESS */
        this._callGetAddress();
        /************GET ADDRESS */

        /************GET CREDIT CARDS */
        this._callGetCreditCards();

        /************GET CREDIT CARDS */

        this.props.loaderStatus(false);
    };

    //show message dialog
    alert = (message, title = "Alert!", btnText = "Close") => {
        this.setState({
            displayDialog: true,
            dialogTitle: title,
            dialogBody: message,
            dialogFooter: (
                <button
                    className="btn"
                    style={styles.dialogCancelButtonStyle}
                    onClick={this.closeDialog}
                >
                    {btnText}
                </button>
            ),
        });
    };

    //Pre checkout validation
    preCheckoutValidate() {
        if (!this.props.cartItems || !this.props.cartItems.length) {
            this.alert("There is nothing in your cart.", "Oops!");
            return false;
        } else if (!this.state.carddetails) {
            this.alert(
                "Please enter card details or choose existing one.",
                "Oops!"
            );
            return false;
        } else if (
            !this.state.carddetails.cardtype ||
            this.state.carddetails.cardtype.trim().length <= 0
        ) {
            this.alert("Please select card type", "Oops!");
            return false;
        } else if (
            !this.state.carddetails.cardholder ||
            this.state.carddetails.cardholder.trim().length <= 0
        ) {
            this.alert("Please enter card holder name.", "Oops!");
            return false;
        } else if (
            !this.state.carddetails.cardnumber ||
            (!this.state.carddetails?.tokenid &&
                this.state.carddetails.cardnumber.toString().trim().length !=
                    16)
        ) {
            this.alert("Invalid card number. Please check.", "Oops!");
            return false;
        } else if (
            !this.state.carddetails.expirymonth ||
            +this.state.carddetails.expirymonth < 1 ||
            this.state.carddetails.expirymonth > 12
        ) {
            this.alert("Invalid card expiry month.", "Oops!");
            return false;
        } else if (!this.state.carddetails.expiryyear) {
            this.alert("Invalid card expiry year.", "Oops!");
            return false;
        } else if (
            new Date(
                this.state.carddetails.expiryyear,
                this.state.carddetails.expirymonth
            ) < new Date()
        ) {
            this.alert("Invalid card expiry month or year.", "Oops!");
            return false;
        } else if (
            !this.state.carddetails.cvv ||
            this.state.carddetails.cvv.toString().length != 3
        ) {
            this.alert("Invalid card cvv.", "Oops!");
            return false;
        }

        return true;
    }

    //Process transactin and get order id
    processTransactin = async (cartItems) => {
        //Request body for processtransaction api
        let processTransactionRequestBody = {
            transactiontype: "RESERVECART",
            source: "SHOPPER",
            reservation: {
                cart: cartItems.map((item) => ({ ...item, cartitemid: 0 })),
                walkin: "N",
            },
        };

        return await processTransaction(processTransactionRequestBody);
    };

    //Transaction status check
    checkTransactionStatus = async (orderid) => {
        return await processCard({
            orderid,
            actiontype: "TRANSACTIONSTATUS",
        });
    };

    processOrder = async () => {
        //validating data before checkout

        if (!this.preCheckoutValidate()) return;

        const PaymentGatewayDetail = JSON.parse(
            localStorage.getItem("PaymentGatewayDetail")
        );

        //Select Payment Gateway
        if (PaymentGatewayDetail.paymentgateway === "FAC") {
            //Get payment gateway configuration
            const FAC_CONFIG = {
                merchantid: PaymentGatewayDetail.merchantid,
                password: PaymentGatewayDetail.password,
                endpoint: PaymentGatewayDetail.endpointpath,
                acquireid: PaymentGatewayDetail.acquirerid,
                currency_code: PaymentGatewayDetail.currencycode,
                transacton_code: PaymentGatewayDetail.transactioncode,
                redirecturl: PaymentGatewayDetail.redirecturl,
                endpointpath: PaymentGatewayDetail.endpointpath,
            };

            this.props.loaderStatus(true);

            let cartTotal = this.props.cartTotal;
            let cardDetails = this.state.carddetails;

            if (cardDetails?.tokenid?.trim()) {
                cardDetails.cardnumber = cardDetails.tokenid;
            }

            //Gateway transaction code
            let transactioncode = 8;

            //if save card is checked update transaction code
            if (
                this.state.saveCard &&
                !this.state.carddetails?.tokenid?.trim()
            ) {
                transactioncode += 128;
            }

            //Process transaction and get order id
            const processTransactionResponse = await this.processTransactin(
                this.props.cartItems
            );

            if (!processTransactionResponse.success) {
                //Failed
                this.props.loaderStatus(false);
                this.alert(processTransactionResponse.statusMessage, "Failed!");
            } else {
                //Check if order id is already used for payment
                const transactionStatusCheck =
                    await this.checkTransactionStatus(
                        processTransactionResponse.data.orderid
                    );

                if (transactionStatusCheck.data.reasoncode === "999") {
                    // order id is ok. proceed

                    //Charge card and get html data
                    let processCardRequestBody = {
                        orderid: processTransactionResponse.data.orderid,
                        actiontype: "CHARGECARD",
                        transactioncode: transactioncode,
                        creditcard: {
                            cardtype: cardDetails.cardtype,
                            cardnumber: cardDetails.cardnumber,
                            cardholder: cardDetails.cardholder,
                            expirydate:
                                cardDetails.expirymonth +
                                "" +
                                cardDetails.expiryyear.toString().substr(-2),
                            cvv: cardDetails.cvv,
                            amount: cartTotal.toFixed(2),
                        },
                    };

                    const processCardResponse = await processCard(
                        processCardRequestBody
                    );

                    if (processCardResponse.success) {
                        this.props.loaderStatus(false);
                        //TODO: manupulating html
                        // var tc = document.createElement("div");
                        // tc.innerHTML = processCardResponse.data.html;
                        // var script = tc.getElementsByTagName("script")[0];
                        // var form = tc.getElementsByTagName("form")[0];
                        // console.log(form);
                        // var btn = document.createElement("button");
                        // btn.type = "submit";
                        // btn.innerHTML = "Submit";
                        // form.appendChild(btn);
                        // script.remove();

                        // var html = `<html><body>${tc.innerHTML}</body></html>`;
                        setTimeout(() => {
                            this.setState({
                                showPaymentModal: true,
                                formHtml: processCardResponse.data.html,
                                iframeLoading: true,
                            });
                            this.addMessageListener(FAC_CONFIG.redirecturl);
                            // Code for payment status interval call
                            // this.startPaymentStatusListener(
                            //     processTransactionResponse.data.orderid
                            // );
                            return;
                        }, 500);
                    } else {
                        // Charge card failed
                        this.props.loaderStatus(false);

                        this.alert(processCardResponse.statusMessage);
                    }
                } else {
                    let data = {
                        ReasonCode: transactionStatusCheck.data.reasoncode,
                        PaddedCardNo: transactionStatusCheck.data.cardnumber,
                        AuthCode:
                            transactionStatusCheck.data.authorizationnumber,
                        ReasonCodeDesc:
                            transactionStatusCheck.data.reasondescription,
                    };
                    this.callConfirmCart(data);
                }
            }
        }
    };

    callConfirmCart = async (data) => {
        //Clear interval if running
        // if (this.state.paymentWaiterInterval) {
        //     window.clearInterval(this.state.paymentWaiterInterval);
        //     this.setState({
        //         paymentWaiterInterval: null,
        //     });
        // }
        console.log(data);
        this.setState({
            formHtml: "",
            showPaymentModal: false,
            iframeLoading: true,
        });

        if (data.ReasonCode == 1) {
            // Payment success. proceed

            //Confirm card
            let paymentBody = {
                paymenttype: "CREDITCARD",
                creditcard: {
                    cardtype: this.state.carddetails.cardtype,
                    cardnumber: data.PaddedCardNo.toString().substr(-4),
                    cardholder: this.state.carddetails.cardholder,
                    expirydate:
                        this.state.carddetails.expiryyear +
                        "" +
                        this.state.carddetails.expirymonth,
                    authorizationnumber: data.AuthCode,
                    tokenid: "************1111",
                    amount: (
                        parseFloat(this.countTotalItemCost()) +
                        parseFloat(this.countTotalItemTax())
                    ).toFixed(2),
                },
            };

            let requestBody = {
                cart: this.props.cartItems,
                payment: paymentBody,
                walkin: "N",
                addtoken: "N",
            };

            if (data.TokenizedPAN) {
                paymentBody.creditcard.tokenid = data.TokenizedPAN;
                requestBody.addtoken = "Y";
            }

            setTimeout(() => {
                this.props.loaderStatus(true);
            }, 800);

            let responseData = await confirmCart(requestBody);

            setTimeout(() => {
                this.props.loaderStatus(false);
            }, 800);

            if (responseData.status === 0) {
                // card confirmed process completed
                //Clear the cart
                this.props.emptyCart();

                //get a new sessionid using silent login
                const signInDetail = JSON.parse(
                    localStorage.getItem("loginDetail")
                );

                const data = {
                    failstatus: 0,
                    username: signInDetail.username,
                    password: signInDetail.password,
                    terms: signInDetail.terms,
                    silentLogin: true,
                    internal: signInDetail.internal,
                };
                this.props.callLoginService(data, this.props.history);

                //Redirect to confirmation screen
                this.props.history.push("/confirmation", {
                    confirmationnumber: responseData.data.confirmationnumber,
                    response: responseData,
                });
            } else {
                // confirm card failed
                this.props.history.push("/failedconfirmation", {
                    statusMessage: responseData.statusMessage,
                });
            }
        } else {
            //FAC payment failed
            this.props.loaderStatus(false);

            let errorMessage = data.ReasonCodeDesc || "";

            if (errorMessage !== "Window is closed") {
                this.alert(errorMessage);
            }

            //FAC Failed so we call processtransaction with 'transactiontype = CANCELCART'
            let cancelCartResponse = await processTransaction({
                transactiontype: "CANCELCART",
            });
            if (!cancelCartResponse.success) {
                this.alert(cancelCartResponse.statusMessage);
            }
        }
    };

    showAddressModal = () => {
        this.setState({
            addressmodalvisible: true,
        });
    };

    showCardModal = () => {
        this.setState({
            creditcardmodalvisible: true,
        });
    };

    hideCardModal = () => {
        this.setState({
            creditcardmodalvisible: false,
        });
    };

    hideAddressModal = () => {
        this.setState({
            addressmodalvisible: false,
        });
    };

    addressInputChangeHandler = (key, value) => {
        let returnObj = {};
        returnObj[key] = value;
        returnObj = {
            ...this.state.address,
            ...returnObj,
        };
        this.setState({
            address: returnObj,
        });
    };

    cardInputChangeHandler = (key, value) => {
        let returnObj = {};
        returnObj[key] = value;
        returnObj = {
            ...this.state.carddetails,
            ...returnObj,
        };
        this.setState({
            carddetails: returnObj,
        });
    };

    addCardInputChangeHandler = (key, value) => {
        let returnObj = {};
        returnObj[key] = value;
        returnObj = {
            ...this.state.addcarddetails,
            ...returnObj,
        };
        this.setState({
            addcarddetails: returnObj,
        });
    };

    appliedAddCreditCardHandle = async (edit = false) => {
        let {
            address1,
            address2,
            address3,
            cardtype,
            cardnumber,
            cardholder,
            phone,
            status,
            expiryyear,
            expirymonth,
            actiontype,
            sequenceid,
            tokenid,
        } = this.state.addcarddetails;

        
        if (actiontype === "CREATE") {
            status = "A";
        }

        if (!this.state.addcarddetails) {
            this.alert("Please enter card details.");
            return;
        } else if (!this.state.addcarddetails?.actiontype?.trim()) {
            this.alert("Unknown action.");
            return;
        } else if (!this.state.addcarddetails?.cardholder?.trim()) {
            this.alert("Please enter card holder name.");
            return;
        } else if (!this.state.addcarddetails?.cardnumber?.trim()) {
            this.alert("Please enter card number.");
            return;
        } else if (
            this.state.addcarddetails?.actiontype === "CREATE" &&
            this.state.addcarddetails?.cardnumber?.length != 16
        ) {
            this.alert("Please enter valid card number.");
            return;
        } else if (!this.state.addcarddetails?.expirymonth?.trim()) {
            this.alert("Please select card expiry month.");
            return;
        } else if (!this.state.addcarddetails?.expiryyear?.trim()) {
            this.alert("Please select card expiry year.");
            return;
        } else if (
            new Date() >
            new Date(
                this.state.addcarddetails.expiryyear,
                this.state.addcarddetails.expirymonth
            )
        ) {
            this.alert("Please select valid card expiry");
            return;
        }

        this.props.loaderStatus(true);
        let expirydate = expiryyear + "" + expirymonth;
        const data = await applyCreditCard({
            actiontype: actiontype,
            sequenceid: sequenceid,
            cardtype: cardtype,
            cardnumber: cardnumber,
            cardholder: cardholder,
            address1: address1,
            address2: address2,
            address3: address3,
            expirydate: expirydate,
            phone: phone,
            status: status,
            tokenid: tokenid,
        });

        if (data.success) {
            this._callGetCreditCards();
            this.setState({
                addcarddetails: {
                    cardholder: "",
                    cardnumber: "",
                    expirymonth: "",
                    expiryyear: "",
                    address1: "",
                    address2: "",
                    address3: "",
                    status: "",
                    tokenid: "",
                    cardtype: "VISA",
                    phone: "",
                    sequenceid: 0,
                    actiontype: "",
                },
            });
        } else {
            this.setState({
                displayDialog: true,
                dialogTitle: "Oops!",
                dialogBody: data.statusMessage,
                dialogFooter: (
                    <button
                        className="btn"
                        style={styles.dialogCancelButtonStyle}
                        onClick={this.closeDialog}
                    >
                        Close
                    </button>
                ),
            });
        }
        this.props.loaderStatus(false);
    };

    appliedAddressHandle = async (edit = false) => {
        if (!this.state.address) {
            this.alert("Please fill all required fields.");
            return;
        } else if (!this.state.address.actiontype) {
            this.alert("Invalid action type.");
            return;
        } else if (!this.state.address.firstname?.trim()) {
            this.alert("Please enter first name.");
            return;
        } else if (!this.state.address.lastname?.trim()) {
            this.alert("Please enter last name.");
            return;
        } else if (!this.state.address.country?.trim()) {
            this.alert("Please select country.");
            return;
        } else if (!this.state.address.phone?.trim()) {
            this.alert("Please enter Phone number.");
            return;
        } else if (!this.state.address.email?.trim()) {
            this.alert("Please enter email address.");
            return;
        } else if (!this.state.address.address1?.trim()) {
            this.alert("Address line 1 is required.");
            return;
        }

        this.props.loaderStatus(true);
        let {
            actiontype,
            sequenceid,
            firstname,
            lastname,
            country,
            address1,
            address2,
            address3,
            email,
            phone,

            status,
        } = this.state.address;

        const data = await applyAddress({
            actiontype,
            sequenceid,
            firstname,
            lastname,
            country,
            address1,
            address2,
            address3,
            email,
            phone,
            status,
        });

        this.setState({
            address: {
                actiontype: "CREATE",
                sequenceid: 0,
                firstname: "",
                lastname: "",
                country: "",
                address1: "",
                address2: "",
                address3: "",
                email: "",
                phone: "",
                phone: "",
                status: "A",
            },
        });
        if (data.status === 0) {
            this._callGetAddress();
            this.setState({
                address: {},
            });
        } else {
            this.setState({
                displayDialog: true,
                dialogTitle: "Oops!",
                dialogBody: data.statusMessage,
                dialogFooter: (
                    <button
                        className="btn"
                        style={styles.dialogCancelButtonStyle}
                        onClick={this.closeDialog}
                    >
                        Close
                    </button>
                ),
            });
        }
        this.props.loaderStatus(false);
    };

    _deleteApplyAddress = async (address) => {
        this.props.loaderStatus(true);
        const data = await applyAddress({
            actiontype: "DELETE",
            sequenceid: address.sequenceid,
            firstname: address.firstname,
            lastname: address.lastname,
            country: address.countrycode,
            address1: address.address1,
            address2: address.address2,
            address3: address.address3,
            email: address.email,
            phone: address.phone,
            status: address.status,
        });
        if (data.status === 0) {
            this._callGetAddress();
        } else {
            this.setState({
                displayDialog: true,
                dialogTitle: "Oops!",
                dialogBody: data.statusMessage,
                dialogFooter: (
                    <button
                        className="btn"
                        style={styles.dialogCancelButtonStyle}
                        onClick={this.closeDialog}
                    >
                        Close
                    </button>
                ),
            });
        }
        this.props.loaderStatus(false);
    };

    _deleteCreditCard = async (creditcard) => {
        this.props.loaderStatus(true);
        const data = await applyCreditCard({
            sequenceid: creditcard.sequenceid,
            actiontype: "DELETE",
        });
        if (data.success) {
            this._callGetCreditCards();
        } else {
            this.setState({
                displayDialog: true,
                dialogTitle: "Oops!",
                dialogBody: data.statusMessage,
                dialogFooter: (
                    <button
                        className="btn"
                        style={styles.dialogCancelButtonStyle}
                        onClick={this.closeDialog}
                    >
                        Close
                    </button>
                ),
            });
        }
        this.props.loaderStatus(false);
    };

    _editAddress = async (address) => {
        this.setState({
            address: {
                actiontype: "UPDATE",
                sequenceid: address.sequenceid,
                firstname: address.firstname,
                lastname: address.lastname,
                country: address.countrycode,
                address1: address.address1,
                address2: address.address2,
                address3: address.address3,
                email: address.email,
                phone: address.phone,
                status: address.status,
            },
        });
    };

    _editCreditCard = async (creditcard) => {
        this.setState({
            addcarddetails: {
                cardholder: creditcard.cardholder,
                cardnumber: creditcard.cardnumber,
                expirymonth: creditcard.expirydate.substring(4, 6),
                expiryyear: creditcard.expirydate.substring(0, 4),
                address1: creditcard.address1,
                address2: creditcard.address2,
                address3: creditcard.address3,
                status: creditcard.status,
                tokenid: creditcard.tokenid,
                cardtype: creditcard.cardtype,
                phone: creditcard.phone,
                sequenceid: creditcard.sequenceid,
                actiontype: "UPDATE",
            },
        });
    };

    _setCheckoutAddress = (address) => {
        this.setState({
            checkoutAddress: address,
            addressmodalvisible: false,
        });
    };

    _setCreditCard = (address) => {
        this.setState({
            checkoutAddress: address,
            addressmodalvisible: false,
        });
    };

    countTotalItemCost = () => {
        let total = 0;
        this.props.cartItems.map((item, index) => {
            total += parseFloat(item.total);
        });

        return total;
    };

    countTotalItemTax = () => {
        let total = 0;
        this.props.cartItems.map((item, index) => {
            total += parseFloat(item.total) * parseFloat(item.taxrate);
        });

        return total;
    };

    _saveCard = (e) => {
        this.setState({
            saveCard: e.target.checked,
        });
    };

    //IFrame events
    iframeContentLoaded = () => {
        console.log("Loaded");
        this.setState({
            iframeLoading: false,
        });
    };

    // add message listener
    addMessageListener = (url) => {
        window.addEventListener("message", (e) => {
            if (new URL(e.origin).host === new URL(url).host) {
                this.callConfirmCart(e.data);
            }
        });
    };

    //add payment status listener
    startPaymentStatusListener = (orderId) => {
        let interval = setInterval(async () => {
            var response = await this.checkTransactionStatus(orderId);
            console.log(response);
        }, 4000);
        this.setState({
            paymentWaiterInterval: interval,
        });
    };

    render() {
        return (
            <>
                <Navbar />
                <Loader message="Processing..." />
                <DialogModal
                    visible={this.state.displayDialog}
                    title={this.state.dialogTitle}
                    body={this.state.dialogBody}
                    footer={this.state.dialogFooter}
                />

                <Breadcrumb style={{ margin: 20, fontSize: 12 }} separator=">">
                    <Breadcrumb.Item>
                        <a href="/">Home</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a href="">Checkout</a>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div style={{ fontWeight: "bold", padding: 40, fontSize: 25 }}>
                    Checkout
                </div>

                {/* Payment Modal */}
                <Modal
                    width="60%"
                    visible={this.state.showPaymentModal}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({
                            showPaymentModal: false,
                            formHtml: "",
                        });
                    }}
                    footer={[
                        <Button
                            type="primary"
                            onClick={() => {
                                this.setState({
                                    showPaymentModal: false,
                                    formHtml: "",
                                });
                            }}
                        >
                            Cancel
                        </Button>,
                    ]}
                >
                    <div style={{ height: "80%" }}>
                        <div
                            style={{
                                marginTop: 50,
                                display: this.state.iframeLoading
                                    ? "block"
                                    : "none",
                                textAlign: "center",
                            }}
                        >
                            <Spin size="large" />
                            <p>
                                Please do not press back button or refresh the
                                window while we process your request...
                            </p>
                        </div>

                        <iframe
                            className={
                                this.state.iframeLoading
                                    ? " d-none"
                                    : " d-block"
                            }
                            onLoad={() => this.iframeContentLoaded()}
                            srcDoc={this.state.formHtml}
                            frameBorder="0"
                            style={{ height: "100%", width: "100%" }}
                            ref={this.iFrameRef}
                            id="paymentIFrame"
                        ></iframe>
                    </div>
                </Modal>
                {/* Address Modal */}
                <Modal
                    width="80%"
                    title="Manage Addresses"
                    visible={this.state.addressmodalvisible}
                    onOk={this.hideAddressModal}
                    onCancel={this.hideAddressModal}
                    footer={[
                        <Button type="primary" onClick={this.hideAddressModal}>
                            Done
                        </Button>,
                    ]}
                >
                    {/* <div style={{ fontSize: 20, fontWeight:'bold', marginLeft:20 }}>Saved Addresses</div> */}
                    <Container>
                        <div>
                            <BSCollapse in={!this.state.address?.actiontype}>
                                <div>
                                    <Row>
                                        {this.state.addresses.length > 0
                                            ? this.state.addresses.map(
                                                  (address, index) => (
                                                      <Col
                                                          key={index}
                                                          className="mb-2"
                                                          sm={6}
                                                          md={3}
                                                          lg={2}
                                                      >
                                                          <div
                                                              style={{
                                                                  border: "1px solid #CCC",
                                                                  padding: 10,
                                                                  borderRadius: 5,
                                                                  fontSize: 11,
                                                                  textAlign:
                                                                      "center",
                                                              }}
                                                          >
                                                              <div
                                                                  style={{
                                                                      fontSize: 10,
                                                                  }}
                                                              >
                                                                  <div>
                                                                      <span>
                                                                          {address.firstname +
                                                                              " " +
                                                                              address.lastname}
                                                                      </span>
                                                                  </div>
                                                                  <div>
                                                                      <span>
                                                                          {" "}
                                                                          {
                                                                              address.address1
                                                                          }
                                                                      </span>
                                                                  </div>
                                                                  <div>
                                                                      <span>
                                                                          {
                                                                              address.phone
                                                                          }
                                                                      </span>
                                                                  </div>
                                                                  <div
                                                                      style={{
                                                                          fontSize: 10,
                                                                          display:
                                                                              "flex",
                                                                          flexDirection:
                                                                              "row",
                                                                          justifyContent:
                                                                              "center",
                                                                      }}
                                                                  >
                                                                      <a
                                                                          style={{
                                                                              marginRight: 20,
                                                                          }}
                                                                          href="#"
                                                                          type="link"
                                                                          onClick={() =>
                                                                              this._setCheckoutAddress(
                                                                                  address
                                                                              )
                                                                          }
                                                                      >
                                                                          Use
                                                                      </a>

                                                                      <a
                                                                          style={{
                                                                              marginRight: 20,
                                                                          }}
                                                                          href="#"
                                                                          type="link"
                                                                          onClick={() =>
                                                                              this._editAddress(
                                                                                  address
                                                                              )
                                                                          }
                                                                      >
                                                                          Edit
                                                                      </a>
                                                                      <Popconfirm
                                                                          title="Are you sure?"
                                                                          placement="top"
                                                                          okText="Delete"
                                                                          onConfirm={() =>
                                                                              this._deleteApplyAddress(
                                                                                  address
                                                                              )
                                                                          }
                                                                      >
                                                                          <a
                                                                              style={{
                                                                                  color: "red",
                                                                              }}
                                                                              type="link"
                                                                          >
                                                                              Delete
                                                                          </a>
                                                                      </Popconfirm>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </Col>
                                                  )
                                              )
                                            : null}

                                        <Col
                                            className="mb-2"
                                            sm={6}
                                            md={3}
                                            lg={2}
                                        >
                                            <div
                                                style={{
                                                    border: "1px solid #CCC",
                                                    padding: 10,
                                                    borderRadius: 5,
                                                    fontSize: 11,
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Button
                                                    type="text"
                                                    onClick={() =>
                                                        this.setState({
                                                            firstNameFocus: true,
                                                            address: {
                                                                actiontype:
                                                                    "CREATE",
                                                            },
                                                        })
                                                    }
                                                    style={{ height: "auto" }}
                                                >
                                                    <PlusOutlined
                                                        style={{ fontSize: 30 }}
                                                    />
                                                    <div
                                                        style={{ fontSize: 11 }}
                                                    >
                                                        Add New
                                                    </div>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </BSCollapse>

                            <BSCollapse in={this.state.address?.actiontype}>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginBottom: 10,
                                        }}
                                    >
                                        <Input
                                            placeholder="First Name"
                                            autofocus={
                                                this.state.firstNameFocus
                                            }
                                            value={this.state.address.firstname}
                                            onChange={(evt) =>
                                                this.addressInputChangeHandler(
                                                    "firstname",
                                                    evt.target.value
                                                )
                                            }
                                            style={{ marginRight: 20 }}
                                        />

                                        <Input
                                            placeholder="Last Name"
                                            value={this.state.address.lastname}
                                            onChange={(evt) =>
                                                this.addressInputChangeHandler(
                                                    "lastname",
                                                    evt.target.value
                                                )
                                            }
                                        />
                                    </div>

                                    <Select
                                        defaultValue="Country/Region"
                                        style={{
                                            width: 190,
                                            marginTop: 10,
                                            marginBottom: 10,
                                        }}
                                        value={this.state.address.country}
                                        onChange={(value, event) =>
                                            this.addressInputChangeHandler(
                                                "country",
                                                value
                                            )
                                        }
                                    >
                                        <Option value="JM">Kingston</Option>
                                        <Option value="IN">St.Ann</Option>
                                        <Option value="Trelawny">
                                            Trelawny
                                        </Option>
                                        <Option value="Yiminghe">
                                            yiminghe
                                        </Option>
                                    </Select>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 10,
                                            marginBottom: 10,
                                        }}
                                    >
                                        <Input
                                            placeholder="Address 1"
                                            value={this.state.address.address1}
                                            onChange={(evt) =>
                                                this.addressInputChangeHandler(
                                                    "address1",
                                                    evt.target.value
                                                )
                                            }
                                            style={{ marginRight: 20 }}
                                        />
                                        <Input
                                            placeholder="Address 2"
                                            value={this.state.address.address2}
                                            onChange={(evt) =>
                                                this.addressInputChangeHandler(
                                                    "address2",
                                                    evt.target.value
                                                )
                                            }
                                        />
                                    </div>

                                    <Input
                                        placeholder="Phone"
                                        value={this.state.address.phone}
                                        onChange={(evt) =>
                                            this.addressInputChangeHandler(
                                                "phone",
                                                evt.target.value
                                            )
                                        }
                                        style={{
                                            marginTop: 10,
                                            marginBottom: 10,
                                        }}
                                    />
                                    <Input
                                        placeholder="Email Address"
                                        style={{
                                            marginTop: 10,
                                            marginBottom: 10,
                                        }}
                                        value={this.state.address.email}
                                        onChange={(evt) =>
                                            this.addressInputChangeHandler(
                                                "email",
                                                evt.target.value
                                            )
                                        }
                                    />

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        <Button
                                            type="primary"
                                            onClick={() =>
                                                this.appliedAddressHandle(false)
                                            }
                                        >
                                            {this.state.address.actiontype ===
                                            "CREATE"
                                                ? "Save"
                                                : "Update"}
                                        </Button>
                                        <Button
                                            type="ghost"
                                            style={{ marginLeft: 20 }}
                                            onClick={() => {
                                                this.setState({
                                                    address: {},
                                                });
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            </BSCollapse>
                        </div>
                    </Container>
                </Modal>

                {/* Card Modal */}
                <Modal
                    width="80%"
                    title="Your saved credit and debit cards"
                    visible={this.state.creditcardmodalvisible}
                    onOk={this.hideCardModal}
                    onCancel={this.hideCardModal}
                    footer={[
                        <Button type="primary" onClick={this.hideCardModal}>
                            Done
                        </Button>,
                    ]}
                >
                    {/* <div style={{ fontSize: 20, fontWeight:'bold', marginLeft:20 }}>Saved Addresses</div> */}
                    <Container>
                        <div>
                            <BSCollapse
                                in={!this.state.addcarddetails?.actiontype}
                            >
                                <div>
                                    <Row>
                                        {this.state.creditcards.length > 0
                                            ? this.state.creditcards.map(
                                                  (creditcard, index) => (
                                                      <Col
                                                          key={index}
                                                          className="mb-2"
                                                          sm={6}
                                                          md={3}
                                                          lg={2}
                                                      >
                                                          <div
                                                              style={{
                                                                  border: "1px solid #CCC",
                                                                  padding: 10,
                                                                  borderRadius: 5,
                                                                  fontSize: 11,
                                                                  textAlign:
                                                                      "center",
                                                              }}
                                                          >
                                                              <div
                                                                  style={{
                                                                      fontSize: 10,
                                                                  }}
                                                              >
                                                                  <div>
                                                                      <span>
                                                                          {
                                                                              creditcard.cardholder
                                                                          }
                                                                      </span>
                                                                  </div>
                                                                  <div>
                                                                      <span>
                                                                          {" "}
                                                                          {
                                                                              creditcard.cardtype
                                                                          }
                                                                      </span>
                                                                  </div>
                                                                  <div>
                                                                      <span>
                                                                          {
                                                                              creditcard.cardnumber
                                                                          }
                                                                      </span>
                                                                  </div>
                                                                  <div
                                                                      style={{
                                                                          fontSize: 10,
                                                                          display:
                                                                              "flex",
                                                                          flexDirection:
                                                                              "row",
                                                                      }}
                                                                  >
                                                                      <a
                                                                          style={{
                                                                              marginRight: 20,
                                                                          }}
                                                                          type="link"
                                                                          onClick={() => {

                                                                            let typeimg = CardTypes.find(c=> c.name.toLowerCase()=== creditcard.cardtype.toLowerCase()).logo;
                                                                              this.setState(
                                                                                  {

                                                                                      carddetails:
                                                                                          {
                                                                                              cardholder:
                                                                                                  creditcard.cardholder,
                                                                                              cardnumber:
                                                                                                  "xxxxxxxxxxxx" +
                                                                                                  creditcard.cardnumber,
                                                                                              expirymonth:
                                                                                                  creditcard.expirydate
                                                                                                      .toString()
                                                                                                      .substring(
                                                                                                          4,
                                                                                                          6
                                                                                                      ),
                                                                                              expiryyear:
                                                                                                  creditcard.expirydate
                                                                                                      .toString()
                                                                                                      .substring(
                                                                                                          0,
                                                                                                          4
                                                                                                      ),
                                                                                              cvv: "",
                                                                                              tokenid:
                                                                                                  creditcard.tokenid,
                                                                                              cardtype:
                                                                                                  creditcard.cardtype,
                                                                                            cardtypeimg: typeimg
                                                                                          },
                                                                                      saveCard: false,
                                                                                  }
                                                                              );
                                                                              this.hideCardModal();
                                                                          }}
                                                                      >
                                                                          Use
                                                                      </a>

                                                                      <a
                                                                          style={{
                                                                              marginRight: 20,
                                                                          }}
                                                                          type="link"
                                                                          onClick={() =>
                                                                              this._editCreditCard(
                                                                                  creditcard
                                                                              )
                                                                          }
                                                                      >
                                                                          Edit
                                                                      </a>

                                                                      <Popconfirm
                                                                          title="Are you sure?"
                                                                          placement="top"
                                                                          okText="Delete"
                                                                          onConfirm={() =>
                                                                              this._deleteCreditCard(
                                                                                  creditcard
                                                                              )
                                                                          }
                                                                      >
                                                                          <a
                                                                              style={{
                                                                                  color: "red",
                                                                              }}
                                                                              type="link"
                                                                          >
                                                                              Delete
                                                                          </a>
                                                                      </Popconfirm>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </Col>
                                                  )
                                              )
                                            : null}

                                        <Col
                                            className="mb-2"
                                            sm={6}
                                            md={3}
                                            lg={2}
                                        >
                                            <div
                                                style={{
                                                    border: "1px solid #CCC",
                                                    padding: 10,
                                                    borderRadius: 5,
                                                    fontSize: 11,
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Button
                                                    type="text"
                                                    onClick={() =>
                                                        this.setState({
                                                            firstNameFocus: true,
                                                            addcarddetails: {
                                                                actiontype:
                                                                    "CREATE",
                                                            },
                                                        })
                                                    }
                                                    style={{ height: "auto" }}
                                                >
                                                    <PlusOutlined
                                                        style={{ fontSize: 30 }}
                                                    />
                                                    <div
                                                        style={{ fontSize: 11 }}
                                                    >
                                                        Add New
                                                    </div>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </BSCollapse>
                            <BSCollapse
                                in={this.state.addcarddetails?.actiontype}
                            >
                                <Col md={6} lg={4}>
                                    <div>
                                        <div>
                                            <Input
                                                placeholder="Cardholder Name (exactly as shown on card)"
                                                value={
                                                    this.state.addcarddetails
                                                        .cardholder
                                                }
                                                onChange={(evt) =>
                                                    this.addCardInputChangeHandler(
                                                        "cardholder",
                                                        evt.target.value
                                                    )
                                                }
                                                style={{ marginBottom: 20 }}
                                            />
                                            <Input
                                                placeholder="Card Number"
                                                value={
                                                    this.state.addcarddetails
                                                        .cardnumber
                                                }
                                                onChange={(evt) => {
                                                                if (
                                                                    evt.target
                                                                        .value
                                                                        ?.length ==
                                                                    16
                                                                ) {
                                                                    let res =
                                                                        getCardType(
                                                                            evt
                                                                                .target
                                                                                .value
                                                                        );
                                                                    if (res) {
                                                                        this.setState(
                                                                            {
                                                                                addcarddetails:
                                                                                    {
                                                                                        ...this
                                                                                            .state
                                                                                            .addcarddetails,
                                                                                        cardtypeimg:
                                                                                            res.logo,
                                                                                        cardtype:
                                                                                            res.name,
                                                                                        cardnumber:
                                                                                            evt
                                                                                                .target
                                                                                                .value,
                                                                                    },
                                                                            }
                                                                        );
                                                                    }
                                                                } else {
                                                                    this.setState(
                                                                        {
                                                                            addcarddetails:
                                                                                {
                                                                                    ...this
                                                                                        .state
                                                                                        .addcarddetails,
                                                                                    cardtypeimg:
                                                                                        null,
                                                                                    cardtype:
                                                                                        null,
                                                                                    cardnumber:
                                                                                        evt
                                                                                            .target
                                                                                            .value,
                                                                                },
                                                                        }
                                                                    );
                                                                }
                                                            }}


                                                maxLength={16}
                                                onKeyPress={(event) => {
                                                    if (
                                                        event.key == "" ||
                                                        event.key == " " ||
                                                        isNaN(event.key)
                                                    ) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                style={{ marginBottom: 20 }}


                                                suffix={
                                                                this.state
                                                                    .addcarddetails
                                                                    .cardtype ? (
                                                                    <img
                                                                        src={
                                                                            this
                                                                                .state
                                                                                .addcarddetails
                                                                                .cardtypeimg
                                                                        }
                                                                        style={{
                                                                            height: 28,
                                                                            marginTop:
                                                                                -5,
                                                                            marginBottom:
                                                                                -5,
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <span />
                                                                )
                                                            }
                                            />
                                            <Input
                                                placeholder="Phone Number"
                                                value={
                                                    this.state.addcarddetails
                                                        .phone
                                                }
                                                onChange={(evt) =>
                                                    this.addCardInputChangeHandler(
                                                        "phone",
                                                        evt.target.value
                                                    )
                                                }
                                                maxLength={10}
                                                onKeyPress={(event) => {
                                                    if (
                                                        event.key == "" ||
                                                        event.key == " " ||
                                                        isNaN(event.key)
                                                    ) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                style={{ marginBottom: 20 }}
                                            />
                                        </div>

                                        <Row>
                                            <Col>
                                                <Input
                                                    onKeyPress={(event) => {
                                                        if (
                                                            event.key == "" ||
                                                            event.key == " " ||
                                                            isNaN(event.key)
                                                        ) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    maxLength="2"
                                                    placeholder="mm"
                                                    className="w-100"
                                                    value={
                                                        this.state
                                                            .addcarddetails
                                                            .expirymonth
                                                    }
                                                    onChange={(event) =>
                                                        this.addCardInputChangeHandler(
                                                            "expirymonth",
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </Col>
                                            <Col>
                                                <Input
                                                    onKeyPress={(event) => {
                                                        if (
                                                            event.key == "" ||
                                                            event.key == " " ||
                                                            isNaN(event.key)
                                                        ) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    maxLength="4"
                                                    placeholder="yyyy"
                                                    className="w-100"
                                                    value={
                                                        this.state
                                                            .addcarddetails
                                                            .expiryyear
                                                    }
                                                    onChange={(event) =>
                                                        this.addCardInputChangeHandler(
                                                            "expiryyear",
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </Col>
                                        </Row>

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginTop: 10,
                                                marginBottom: 0,
                                            }}
                                        >
                                            <Input
                                                placeholder="Address 1"
                                                value={
                                                    this.state.addcarddetails
                                                        .address1
                                                }
                                                onChange={(evt) =>
                                                    this.addCardInputChangeHandler(
                                                        "address1",
                                                        evt.target.value
                                                    )
                                                }
                                                style={{ marginBottom: 20 }}
                                            />
                                            <Input
                                                placeholder="Address 2"
                                                value={
                                                    this.state.addcarddetails
                                                        .address2
                                                }
                                                onChange={(evt) =>
                                                    this.addCardInputChangeHandler(
                                                        "address2",
                                                        evt.target.value
                                                    )
                                                }
                                                style={{ marginBottom: 20 }}
                                            />
                                            <Input
                                                placeholder="Address 3"
                                                value={
                                                    this.state.addcarddetails
                                                        .address3
                                                }
                                                onChange={(evt) =>
                                                    this.addCardInputChangeHandler(
                                                        "address3",
                                                        evt.target.value
                                                    )
                                                }
                                                style={{ marginBottom: 20 }}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        <Button
                                            type="primary"
                                            onClick={() =>
                                                this.appliedAddCreditCardHandle(
                                                    false
                                                )
                                            }
                                        >
                                            {this.state.addcarddetails
                                                .actiontype === "CREATE"
                                                ? "Save"
                                                : "Update"}
                                        </Button>
                                        <Button
                                            type="ghost"
                                            style={{ marginLeft: 20 }}
                                            onClick={() => {
                                                this.setState({
                                                    addcarddetails: {},
                                                });
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Col>
                            </BSCollapse>
                        </div>
                    </Container>
                </Modal>

                <Container style={{ backgroundColor: "#FFF" }}>
                    <Row>
                        <Col md={9}>
                            <Collapse
                                accordion
                                bordered={false}
                                defaultActiveKey={["1"]}
                            >
                                <Panel
                                    showArrow={false}
                                    style={{ borderColor: "#F9F9F9" }}
                                    header={
                                        <div
                                            style={{
                                                padding: 10,
                                                fontSize: 20,
                                                fontWeight: "bold",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div>
                                                <Avatar
                                                    size={44}
                                                    icon={<div>1</div>}
                                                />
                                                <span
                                                    style={{ marginLeft: 20 }}
                                                >
                                                    Shipping
                                                </span>
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent:
                                                        "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <Button type="link">
                                                        Edit
                                                    </Button>
                                                </div>
                                                <div style={{ marginTop: 20 }}>
                                                    <CheckCircleOutlined
                                                        style={{
                                                            fontSize: 40,
                                                            color: "#4CAF50",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    key="1"
                                >
                                    <div
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: 16,
                                        }}
                                    >
                                        How would you like to get your order?
                                    </div>

                                    <Tabs type="card" style={{ marginTop: 20 }}>
                                        <TabPane
                                            tab={
                                                <div
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: 14,
                                                        padding: 20,
                                                    }}
                                                >
                                                    Ship to Your Location
                                                </div>
                                            }
                                            key="1"
                                        >
                                            <div>
                                                <p>
                                                    Have you order delivered to
                                                    your home, office or
                                                    anywhere. We work with a
                                                    number of different carriers
                                                    & will ship via the one who
                                                    can best meet your delivery
                                                    needs.
                                                </p>
                                            </div>
                                            <div style={{ width: 500 }}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            border: "1px solid #CCC",
                                                            width: 220,
                                                            padding: 10,
                                                            borderRadius: 5,
                                                            fontSize: 11,
                                                        }}
                                                    >
                                                        {this.state
                                                            .checkoutAddress
                                                            .sequenceid ? (
                                                            <div
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <div>
                                                                    <span>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .checkoutAddress
                                                                                .address1
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .checkoutAddress
                                                                                .address2
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .checkoutAddress
                                                                                .phone
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <Button
                                                                        type="dashed"
                                                                        onClick={() =>
                                                                            this.showAddressModal()
                                                                        }
                                                                    >
                                                                        Change
                                                                        Address
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <div>
                                                                    <span>
                                                                        Address
                                                                        is not
                                                                        created
                                                                    </span>
                                                                </div>

                                                                <div>
                                                                    <Button
                                                                        type="dashed"
                                                                        onClick={() =>
                                                                            this.showAddressModal()
                                                                        }
                                                                    >
                                                                        Add
                                                                        Address
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>

                                                    {/* <div style={{ border:'1px solid #CCC', width:160, padding:10, borderRadius: 5, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'  }}>                  
                                                        <Button type="link" onClick={() => this.showAddressModal()}>Add New Address</Button>
                                                    </div> */}
                                                </div>

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent:
                                                            "flex-start",
                                                        marginTop: 20,
                                                    }}
                                                >
                                                    <Button type="primary">
                                                        Next
                                                    </Button>
                                                </div>

                                                {/* <div style={{ display:'flex', flexDirection:'row', marginTop:10, marginBottom:10 }}>

                                                    <Input placeholder="First Name" style={{ marginRight:20 }}/>

                                                    <Input placeholder="Last Name" />

                                                </div>
                                             

                                                <Select defaultValue="Country/Region" style={{ width: 190, marginTop:10, marginBottom:10 }}>
                                                    <Option value="Kingston">Kingston</Option>
                                                    <Option value="StAnn">St.Ann</Option>
                                                    <Option value="Trelawny">Trelawny</Option>
                                                    <Option value="Yiminghe">yiminghe</Option>
                                                </Select>

                                                <div style={{ display:'flex', flexDirection:'row', marginTop:10, marginBottom:10 }}>

                                                    <Input placeholder="Address 1" style={{ marginRight:20 }}/>
                                                    <Input placeholder="Address 2" />

                                                </div>

                                                <Input placeholder="Phone" style={{ marginTop:10, marginBottom:10 }}/>
                                                <Input placeholder="Email Address" style={{ marginTop:10, marginBottom:10 }}/>

                                                <div style={{ display:'flex', flexDirection:'row', justifyContent:'flex-start' }}>
                                                    <Button type="primary">Save</Button>
                                                </div> */}
                                            </div>
                                        </TabPane>

                                        {/* TODO: pickup and dropoff location not implemented */}

                                        <TabPane
                                            tab={
                                                <div
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: 14,
                                                        padding: 20,
                                                    }}
                                                >
                                                    Pick Up at Drop Off Location
                                                </div>
                                            }
                                            key="2"
                                        >
                                            <div style={{ width: 400 }}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        marginTop: 10,
                                                        marginBottom: 10,
                                                    }}
                                                >
                                                    <Select
                                                        defaultValue="Country/Region"
                                                        style={{
                                                            width: 190,
                                                            marginBottom: 20,
                                                        }}
                                                    >
                                                        <Option value="Kingston">
                                                            Kingston
                                                        </Option>
                                                        <Option value="StAnn">
                                                            St.Ann
                                                        </Option>
                                                        <Option value="Trelawny">
                                                            Trelawny
                                                        </Option>
                                                        <Option value="Yiminghe">
                                                            yiminghe
                                                        </Option>
                                                    </Select>

                                                    <Select
                                                        defaultValue="Pick Up Location"
                                                        style={{
                                                            width: 190,
                                                            marginBottom: 20,
                                                        }}
                                                    >
                                                        <Option value="Location 1">
                                                            Location 1
                                                        </Option>
                                                        <Option value="Location 2">
                                                            Location 2
                                                        </Option>
                                                        <Option value="Location 3">
                                                            Location 3
                                                        </Option>
                                                        <Option value="Location 4">
                                                            Location 4
                                                        </Option>
                                                    </Select>
                                                </div>

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent:
                                                            "flex-start",
                                                    }}
                                                >
                                                    <Button type="primary">
                                                        Save
                                                    </Button>
                                                </div>
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </Panel>

                                <Panel
                                    showArrow={false}
                                    style={{ borderWidth: 0 }}
                                    header={
                                        <div
                                            style={{
                                                padding: 10,
                                                fontSize: 20,
                                                fontWeight: "bold",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div>
                                                <Avatar
                                                    size={44}
                                                    icon={<div>2</div>}
                                                />
                                                <span
                                                    style={{ marginLeft: 20 }}
                                                >
                                                    Payment
                                                </span>
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent:
                                                        "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <Button type="link">
                                                        Edit
                                                    </Button>
                                                </div>
                                                <div style={{ marginTop: 20 }}>
                                                    <CheckCircleOutlined
                                                        style={{
                                                            fontSize: 40,
                                                            color: "#4CAF50",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    key="2"
                                >
                                    <Tabs type="card" style={{ marginTop: 20 }}>
                                        <TabPane
                                            tab={
                                                <div
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: 14,
                                                        padding: 20,
                                                    }}
                                                >
                                                    Credit Card
                                                </div>
                                            }
                                            key="1"
                                        >
                                            <div style={{ width: 400 }}>
                                                <h4>
                                                    Your saved credit and debit
                                                    cards
                                                </h4>
                                                <Button
                                                    type="dashed"
                                                    onClick={() =>
                                                        this.showCardModal()
                                                    }
                                                >
                                                    Saved Cards
                                                </Button>
                                                {this.state.carddetails
                                                    ?.tokenid ? (
                                                    <Button
                                                        className="ml-2"
                                                        visible={
                                                            this.state
                                                                .carddetails
                                                                ?.tokenid
                                                        }
                                                        type="dashed"
                                                        onClick={() =>
                                                            this.setState({
                                                                carddetails: {},
                                                            })
                                                        }
                                                    >
                                                        Use New Card
                                                    </Button>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div style={{ width: 400 }}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        marginTop: 10,
                                                        marginBottom: 10,
                                                    }}
                                                >
                                                    <Input
                                                        placeholder="Cardholder Name (exactly as shown on card)"
                                                        value={
                                                            this.state
                                                                .carddetails
                                                                .cardholder
                                                        }
                                                        onChange={(evt) =>
                                                            this.cardInputChangeHandler(
                                                                "cardholder",
                                                                evt.target.value
                                                            )
                                                        }
                                                        style={{
                                                            marginBottom: 20,
                                                        }}
                                                        disabled={
                                                            this.state
                                                                .carddetails
                                                                .tokenid
                                                        }
                                                    />
                                                    
                                                        <Input
                                                            suffix={
                                                                this.state
                                                                    .carddetails
                                                                    .cardtype ? (
                                                                    <img
                                                                        src={
                                                                            this
                                                                                .state
                                                                                .carddetails
                                                                                .cardtypeimg
                                                                        }
                                                                        style={{
                                                                            height: 28,
                                                                            marginTop:
                                                                                -5,
                                                                            marginBottom:
                                                                                -5,
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <span />
                                                                )
                                                            }
                                                            onKeyPress={(
                                                                event
                                                            ) => {
                                                                if (
                                                                    event.key ==
                                                                        "" ||
                                                                    event.key ==
                                                                        " " ||
                                                                    isNaN(
                                                                        event.key
                                                                    )
                                                                ) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            placeholder="Card Number"
                                                            value={
                                                                this.state
                                                                    .carddetails
                                                                    .cardnumber
                                                            }
                                                            disabled={
                                                                this.state
                                                                    .carddetails
                                                                    .tokenid
                                                            }
                                                            onChange={(evt) => {
                                                                if (
                                                                    evt.target
                                                                        .value
                                                                        ?.length ==
                                                                    16
                                                                ) {
                                                                    let res =
                                                                        getCardType(
                                                                            evt
                                                                                .target
                                                                                .value
                                                                        );
                                                                    if (res) {
                                                                        this.setState(
                                                                            {
                                                                                carddetails:
                                                                                    {
                                                                                        ...this
                                                                                            .state
                                                                                            .carddetails,
                                                                                        cardtypeimg:
                                                                                            res.logo,
                                                                                        cardtype:
                                                                                            res.name,
                                                                                        cardnumber:
                                                                                            evt
                                                                                                .target
                                                                                                .value,
                                                                                    },
                                                                            }
                                                                        );
                                                                    }
                                                                } else {
                                                                    this.setState(
                                                                        {
                                                                            carddetails:
                                                                                {
                                                                                    ...this
                                                                                        .state
                                                                                        .carddetails,
                                                                                    cardtypeimg:
                                                                                        null,
                                                                                    cardtype:
                                                                                        null,
                                                                                    cardnumber:
                                                                                        evt
                                                                                            .target
                                                                                            .value,
                                                                                },
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                            maxLength="16"
                                                        />
                                                </div>

                                                <div
                                                    style={{ marginBottom: 20 }}
                                                >
                                                    <div
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Expiration Date
                                                    </div>
                                                    <Input
                                                        onKeyPress={(event) => {
                                                            if (
                                                                event.key ==
                                                                    "" ||
                                                                event.key ==
                                                                    " " ||
                                                                isNaN(event.key)
                                                            ) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        placeholder="mm"
                                                        maxLength="2"
                                                        disabled={
                                                            this.state
                                                                .carddetails
                                                                .tokenid
                                                        }
                                                        value={
                                                            this.state
                                                                .carddetails
                                                                .expirymonth
                                                        }
                                                        maxLength="2"
                                                        style={{
                                                            width: 120,
                                                            marginRight: 10,
                                                        }}
                                                        onChange={(evt) =>
                                                            this.cardInputChangeHandler(
                                                                "expirymonth",
                                                                evt.target.value
                                                            )
                                                        }
                                                    />
                                                    <Input
                                                        maxLength="4"
                                                        disabled={
                                                            this.state
                                                                .carddetails
                                                                .tokenid
                                                        }
                                                        onKeyPress={(event) => {
                                                            if (
                                                                event.key ==
                                                                    "" ||
                                                                event.key ==
                                                                    " " ||
                                                                isNaN(event.key)
                                                            ) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        style={{
                                                            width: 120,
                                                            marginRight: 10,
                                                        }}
                                                        placeholder="yyyy"
                                                        value={
                                                            this.state
                                                                .carddetails
                                                                .expiryyear
                                                        }
                                                        onChange={(evt) =>
                                                            this.cardInputChangeHandler(
                                                                "expiryyear",
                                                                evt.target.value
                                                            )
                                                        }
                                                    />
                                                </div>

                                                <div
                                                    style={{ marginBottom: 20 }}
                                                >
                                                    <Input
                                                        onKeyPress={(event) => {
                                                            if (
                                                                event.key ==
                                                                    "" ||
                                                                event.key ==
                                                                    " " ||
                                                                isNaN(event.key)
                                                            ) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        placeholder="CVV"
                                                        style={{ width: 120 }}
                                                        value={
                                                            this.state
                                                                .carddetails.cvv
                                                        }
                                                        maxLength="3"
                                                        onChange={(evt) =>
                                                            this.cardInputChangeHandler(
                                                                "cvv",
                                                                evt.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    style={{ marginBottom: 20 }}
                                                >
                                                    <Checkbox
                                                        disabled={
                                                            this.state
                                                                .carddetails
                                                                .tokenid &&
                                                            this.state.carddetails?.tokenid?.trim()
                                                                .length
                                                        }
                                                        onChange={
                                                            this._saveCard
                                                        }
                                                    >
                                                        Save this card for
                                                        faster checkout.
                                                    </Checkbox>
                                                </div>

                                                <div>
                                                    <Button type="primary">
                                                        Review Your Order
                                                    </Button>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane
                                            tab={
                                                <div
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: 14,
                                                        padding: 20,
                                                    }}
                                                >
                                                    Paypal
                                                </div>
                                            }
                                            key="2"
                                        >
                                            <div
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: 16,
                                                    marginBottom: 20,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div>
                                                    Pay with PayPal
                                                    <div
                                                        style={{
                                                            fontWeight: "500",
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        By selecting PayPal, you
                                                        will securely log in to
                                                        your PayPal account and
                                                        complete the order
                                                        transaction.
                                                    </div>
                                                </div>

                                                <div>
                                                    <img
                                                        style={{
                                                            width: 200,
                                                            height: "auto",
                                                        }}
                                                        src={paypal}
                                                    />
                                                </div>
                                            </div>

                                            <Button
                                                type="primary"
                                                style={{ marginTop: 20 }}
                                            >
                                                Review Your Order
                                            </Button>
                                        </TabPane>
                                    </Tabs>
                                </Panel>
                                {/* 
                                <Panel showArrow={false} style={{ borderWidth:0 }} header={<div style={{ padding:20, fontSize: 20, fontWeight:'bold' }}>1. Shipping</div>} key="3">
                                    <p>Test 3</p>
                                </Panel> */}
                            </Collapse>
                        </Col>
                        <Col md={3} style={{ backgroundColor: "#F9F9F9" }}>
                            <div style={{ padding: 20 }}>
                                <div
                                    style={{
                                        fontSize: 22,
                                        fontWeight: "bold",
                                        marginBottom: 20,
                                    }}
                                >
                                    Order Summary
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        marginBottom: 10,
                                        marginTop: 10,
                                    }}
                                >
                                    <div>
                                        <a href="#" style={{ fontSize: 10 }}>
                                            Deliver to Kennewick, Washington
                                        </a>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        height: 1,
                                        backgroundColor: "#DDD",
                                        marginTop: 10,
                                    }}
                                ></div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        marginBottom: 10,
                                        marginTop: 10,
                                    }}
                                >
                                    <div>Item(s):</div>

                                    <div style={{ fontWeight: "bold" }}>
                                        ${this.countTotalItemCost()}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        marginBottom: 10,
                                        marginTop: 10,
                                    }}
                                >
                                    <div>Sales Tax:</div>
                                    <div style={{ fontWeight: "bold" }}>
                                        ${this.countTotalItemTax()}
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        marginBottom: 10,
                                        marginTop: 10,
                                    }}
                                >
                                    <div>Discount (0%):</div>
                                    <div style={{ fontWeight: "bold" }}>
                                        {" "}
                                        $00.00
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        marginBottom: 10,
                                        marginTop: 10,
                                    }}
                                >
                                    <div>Shipping:</div>
                                    <div style={{ fontWeight: "bold" }}>
                                        $00.00
                                    </div>
                                </div>

                                <div
                                    style={{
                                        height: 1,
                                        backgroundColor: "#DDD",
                                        marginTop: 10,
                                    }}
                                ></div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        marginBottom: 10,
                                        marginTop: 20,
                                    }}
                                >
                                    <div style={{ fontSize: 22 }}>Total:</div>
                                    <div
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: 22,
                                        }}
                                    >
                                        $
                                        {this.countTotalItemCost() +
                                            this.countTotalItemTax()}
                                    </div>
                                </div>
                            </div>

                            <div>
                                <Button
                                    block
                                    type="primary"
                                    onClick={() => this.processOrder()}
                                >
                                    Pay Now
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    authed: state.SignInReducer.authed,
    logindetails: state.SignInReducer.logindetails,
    cartItems: state.CartReducer.cart,
    cartTotal: state.CartReducer.cartTotal,
});
export default connect(mapStateToProps, {
    emptyCart,
    loaderStatus,
    callLoginService,
})(PaymentDetails);
