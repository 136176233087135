import React, { Component } from "react";
import {
  Form,
  FormControl,
  Container,
  Row,
  Col,
  Image,
  Card,
  Dropdown,
  Carousel,
} from "react-bootstrap";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { Rate, Select, Button, Breadcrumb, Drawer } from "antd";
import nikeshoes1 from "../images/nikeshoes1.jpg";
import nikeshoes2 from "../images/nikeshoes2.jpg";
import nikeshoes3 from "../images/nikeshoes3.jpg";
import nikeshoes4 from "../images/nikeshoes4.jpg";
import { addToCart } from "../actions/public/cartAction";
import { connect } from "react-redux";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import {
  TwitterOutlined,
  FacebookFilled,
  InstagramFilled,
  MailOutlined,
} from "@ant-design/icons";

import { getProducts } from "../actions/public/getProducts";
import { APPLICATION_CONST } from "../config/customConfig";

const contentStyle = {
  height: "500px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

const { Option } = Select;

const handleDragStart = (e) => e.preventDefault();

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 5 },
};

class ProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawervisible: false,
      relatedproducts: [],
      vendorproducts: [],
      product: this.props.history.location.state.product
    };
  }

  renderRelatedProducts = (products) => {
    let tempProducts = [];

    if (products && products.length > 0) {
      products.forEach((product, index) => {        
        tempProducts.push(
          <div onDragStart={handleDragStart} className={"relatedproductitem"}>
            <img
              style={{ width: "inherit", height: "auto" }}
              src={
                APPLICATION_CONST.productImageUrl +
                product.productreferenceid +
                ".png" +
                "?" +
                new Date()
              }
            />
            <div style={{ textAlign: "center", marginTop: 10 }}>
              {this.goToCartCheck(product.productid) ? (
                <Button
                  onClick={() => this.props.history.push("/shoppingcart")}
                  style={{ fontSize: 10 }}
                  type="dashed"
                  alreadyCartAdded={
                    this.goToCartCheck(product.productid) ? true : false
                  }
                  product={product}
                >
                  Go To Cart
                </Button>
              ) : (
                <Button
                  onClick={() => this._addToCart(product)}
                  style={{ fontSize: 10 }}
                  type="dashed"
                >
                  Add To Cart
                </Button>
              )}

              <div style={{ fontWeight: "bold" }}>{product.productname}</div>
              <div style={{ color: "#03A9F4" }}>${product.msrp}</div>
            </div>
          </div>
        );
      });
    }

    this.setState({
      relatedproducts: tempProducts,
    });    
  };

  renderOtherVendorsProducts = (products) => {
    let tempProducts = [];

    if (products && products.length > 0) {
      products.forEach((product, index) => {        
        tempProducts.push(
          <div
            style={{
              backgroundColor: "#F9F9F9",
              padding: 5,
              borderRadius: 5,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <div
              style={{
                fontSize: 12,
                fontWeight: "bold",
                marginBottom: 10,
              }}
            >
              {product.productname}
            </div>
            <div
              style={{
                fontSize: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontSize: 11, fontWeight: "bold" }}>
                <div>
                  Sold By:{" "}
                  <span style={{ fontWeight: "500" }}>
                    {" "}
                    {product.vendorname}
                  </span>
                </div>
                <div style={{ fontSize: 10 }}>
                  Ships From:{" "}
                  <span style={{ fontWeight: "500" }}>Location 3</span>
                </div>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: "500",
                    color: "#000",
                  }}
                >
                  ${product.msrp.toFixed(2)}
                </div>
              </div>
              <div>
                {this.goToCartCheck(product.productid) ? (
                  <Button
                    onClick={() => this.props.history.push("/shoppingcart")}
                    style={{ fontSize: 10 }}
                    type="primary"
                    alreadyCartAdded={
                      this.goToCartCheck(product.productid) ? true : false
                    }
                    product={product}
                  >
                    Go To Cart
                  </Button>
                ) : (
                  <Button
                    onClick={() => this._addToCart(product)}
                    style={{ fontSize: 10 }}
                    type="primary"
                  >
                    Add To Cart
                  </Button>
                )}
              </div>
            </div>
          </div>
        );
      });
    }

    this.setState({
      vendorproducts: tempProducts,
    });    
  };

  callGetProducts = async () => {
    if (this.props.match.params.productId) {
      const productid = this.props.match.params.productId;
      const products = await getProducts({
        productid: productid,
      });


      this.renderRelatedProducts(products.data.productlists?.relatedproducts);
      this.renderOtherVendorsProducts(products.data.productlists?.othervendors);
    }
  };
  componentDidMount() {
    
    if(!this.state.product)
    {
      this.props.history.push("/");
    }
    this.callGetProducts();
  }

  navigateToCategories = () => {
    console.log(this.props);
  };

  navigateToShoppingCart = () => {
    this.props.history.push("/shoppingcart");
  };

  onChange(a, b, c) {
    console.log(a, b, c);
  }

  showDrawer = () => {
    this.setState({
      drawervisible: true,
    });
  };

  onClose = () => {
    this.setState({
      drawervisible: false,
    });
  };

  goToCartCheck = (productid) => {
    const cart = this.props.cartItems.filter(
      (product) => product.productid === productid
    ).length;
    return cart;
  };

  _addToCart = (product) => {
    
    this.props.addToCart({
      ...product,
      quantityincart: 1,
    });
  };

  filterCategorys = () => {
    let productlists = this.props.productlists.allproducts;
    let tempCategorys = [];

    productlists.map((product, index) => {
      let categoryid = product.categoryid;
      let categoryname = product.categoryname;

      let obj = tempCategorys.find((o) => o.categoryid === categoryid);

      if (!obj) {
        tempCategorys.push({
          categoryid: categoryid,
          categoryname: categoryname,
        });
      }
    });

    return tempCategorys;
  };

  categoryNameByCategoryId = () => {
    let categorys = this.filterCategorys();
    const categoryId = this.props.match.params.categoryId;
    let category;
    if (categoryId) {
      if (categoryId === "deals") {
        category = "Deals";
      } else if (categoryId === "todaydeals") {
        category = "Today Deals";
      } else {
        let findCategory = categorys.find((o) => o.categoryid === categoryId);
        category = findCategory ? findCategory.categoryname : "";
      }
    } else {
      category = "";
    }

    return category;
  };

  render() {
    const selectedProduct = this.props.history.location.state.product;    
    return (
      <>
        <Navbar />

        <Breadcrumb style={{ margin: 20, fontSize: 12 }} separator=">">
          <Breadcrumb.Item>
            <a href="/">Home</a>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <a href="">Shoes</a>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <a href="">Running Shoes</a>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Container
          fluid
          style={{ marginTop: 10, marginBottom: 10 }}
          style={{ backgroundColor: "#FFF" }}
        >
          <Row className="justify-content-md-center" noGutters>
            <Col md={4}>
              <Carousel>
                <Carousel.Item style={{ padding: 20, height: 500 }}>
                  <img
                    className="img-thumbnail"
                    src={
                      APPLICATION_CONST.productImageUrl +
                      selectedProduct.productreferenceid +
                      ".png" +
                      "?" +
                      new Date()
                    }
                  />
                </Carousel.Item>
              </Carousel>
            </Col>
            <Col md={4}>
              <Card style={{ borderWidth: 0, minHeight: 500 }}>
                <div style={{ fontSize: 20, fontWeight: 600, padding: 20 }}>
                  {selectedProduct.productname}
                  <div style={{ fontSize: 20, fontWeight: 600 }}>
                    <Rate allowHalf defaultValue={2.5} />
                    &nbsp;&nbsp;&nbsp;
                    <span style={{ fontSize: 12, color: "#0D47A1" }}>
                      2,345 Ratings
                    </span>
                  </div>
                  <div
                    style={{
                      height: 1,
                      backgroundColor: "#DDD",
                      marginTop: 10,
                    }}
                  ></div>
                  <div style={{ fontSize: 20 }}>
                    <span style={{ fontSize: 15, marginRight: 10 }}>Share</span>
                    <a href="#" style={{ color: "#222" }}>
                      <FacebookFilled style={{ marginRight: 15 }} />
                    </a>
                    <a href="#" style={{ color: "#222" }}>
                      <TwitterOutlined style={{ marginRight: 15 }} />
                    </a>
                    <a href="#" style={{ color: "#222" }}>
                      <InstagramFilled style={{ marginRight: 15 }} />
                    </a>
                    <a href="#" style={{ color: "#222" }}>
                      <MailOutlined />
                    </a>
                  </div>
                </div>

                <div style={{ padding: 20 }}>
                  <span style={{ marginRight: 10, fontWeight: "bold" }}>
                    Select Size
                  </span>
                  <Select defaultValue="10" style={{ width: 120 }}>
                    <Option value="10">10</Option>
                    <Option value="11">11</Option>
                    <Option value="12">12</Option>
                  </Select>
                </div>

                <div
                  className="cardBodyContainer"
                  style={{ paddingLeft: 20, paddingRight: 20 }}

                  >
                  {
                    this.state.product.description
                  }
                </div>
              </Card>
            </Col>

            <Col md={4}>
              <Card style={{ borderWidth: 0, minHeight: 500 }}>
                {
                  (this.state.vendorproducts?.length)?(
                  <div style={{ padding: 20 }}>
                  <Button
                    type="dashed"
                    block
                    style={{ color: "#222" }}
                    onClick={() => this.showDrawer()}
                  >
                    New ({this.state.vendorproducts.length}) From $2400
                  </Button>
                  <Drawer
                    width={420}
                    title={selectedProduct.productname}
                    placement="right"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.drawervisible}
                  >
                    {this.state.vendorproducts}
                  </Drawer>
                </div>
                  ):""
                }
                <div style={{ padding: 20 }}>
                  {this.goToCartCheck(selectedProduct.productid) ? (
                    <Button
                      onClick={() => this.props.history.push("/shoppingcart")}
                      block
                      style={{
                        backgroundColor: "#2196F3",
                        color: "#FFF",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                      alreadyCartAdded={
                        this.goToCartCheck(selectedProduct.productid)
                          ? true
                          : false
                      }
                      product={selectedProduct}
                    >
                      Go To Cart
                    </Button>
                  ) : (
                    <Button
                      block
                      style={{
                        backgroundColor: "#2196F3",
                        color: "#FFF",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                      onClick={() => this._addToCart(selectedProduct)}
                    >
                      Add To Cart
                    </Button>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container>
          
          {
            this.state.relatedproducts?.length?(
              <>
               <Row>
            <div
              className="col-md-12"
              style={{
                fontWeight: "bold",
                fontSize: 20,
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              Products Related to this Item
            </div>
          </Row>

          <Row>
            <AliceCarousel
              mouseTracking
              items={this.state.relatedproducts}
              paddingLeft={50}
              paddingRight={50}
              responsive={responsive}
              disableButtonsControls={true}
            />
          </Row>

              </>
            ):""
            
          }
          
         
          <Row>
            <div
              className="col-md-12"
              style={{ fontWeight: "bold", fontSize: 20 }}
            >
              Product Description
            </div>
            <div
              style={{
                fontSize: 12,
                paddingBottom: 25,
                paddinTop: 10,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              {selectedProduct.description}
            </div>
          </Row>

          <div style={{ height: 1, backgroundColor: "#DDD" }}></div>

          <Row>
            <div
              className="col-md-12"
              style={{
                fontWeight: "bold",
                fontSize: 20,
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              Product Details
            </div>
            <ul>
              <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
              <li>Aliquam tincidunt mauris eu risus.</li>
              <li>Vestibulum auctor dapibus neque.</li>
              <li>Nunc dignissim risus id metus.</li>
              <li>Cras ornare tristique elit.</li>
              <li>Vivamus vestibulum ntulla nec ante.</li>
              <li>Praesent placerat risus quis eros.</li>
              <li>Fusce pellentesque suscipit nibh.</li>
              <li>Integer vitae libero ac risus egestas placerat.</li>
            </ul>
          </Row>
        </Container>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authed: state.SignInReducer.authed,
  logindetails: state.SignInReducer.logindetails,
  cartItems: state.CartReducer.cart,
  productlists: state.SignInReducer.logindetails.productlists,
});
export default connect(mapStateToProps, { addToCart })(ProductDetails);
