import axios from "axios";
import { APPLICATION_CONST,FAC_MERCHANT_URL } from "../../config/customConfig";

export const getPaymentGateway = (data) => {
  return new Promise((resolve, reject) => {
    const body = {
      username: data.username,
      sessionid: data.sessionid,
      failstatus: 0,
      request: {},
    };

 
    axios({
      method: "post",
      url: `${APPLICATION_CONST.url}getpaymentgateway`,
      data: body,
    })
      .then((response) => {
       
         // ******** save login detail into local storage *********//
        let setPaymentData = {
          ...response.data.data,
          redirecturl:  FAC_MERCHANT_URL,
        };

         localStorage.setItem(
          "PaymentGatewayDetail",
          JSON.stringify(setPaymentData)
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  });
};
