import axios from "axios";

import {
    LOGIN_SERVICE_ERROR,
    LOGIN_SERVICE_SUCCESS,
    LOGIN_SERVICE_PENDING,
    FORCE_RESET_PASSWORD,
} from "./actionTypes";
import { APPLICATION_CONST } from "../../config/customConfig";
import { FETCH_STORES } from "../public/actionTypes";
import { productCategory } from "../vendor/productAction";
import { updateFlightForm } from "../../actions/public/flightInfoAction";
import { emptyCart } from "../public/cartAction";
import { loaderStatus } from "../vendor/loaderAction";
import { getPaymentGateway } from "../common/PaymentGatewayAction";

export const loginServicePending = () => ({
    type: LOGIN_SERVICE_PENDING
});

export const loginServiceSuccess = (data) => ({
    type: LOGIN_SERVICE_SUCCESS,
    payload: data,
});

export const loginServiceError = (data) => ({
    type: LOGIN_SERVICE_ERROR,
    payload: data,
});

export const callLoginService = (data, history, path) => (dispatch) => {
    const body = {
        failstatus: 0,
        request: {
            username: data.username,
            password: data.password,
        },
    };

    dispatch(loginServicePending(data.silentLogin));
    if (data.silentLogin != true) {
        dispatch(emptyCart());
        dispatch(loaderStatus(true));
    }

    axios({
        method: "post",
        url: APPLICATION_CONST.url + "login",
        data: body,
    })
        .then((response) => {
            if (response.data.status === 0) {
                const responseData = {
                    ...response.data.data,
                    internal: data.internal,
                };
                const now = new Date();
                const loginDetail = {
                    username: data.username,
                    password: data.password,
                    sessionid: response.data.data.sessionid,
                    terms: data.terms,
                    time: now.getTime(),
                    internal: data.internal,
                };
                // ******** save login detail into local storage *********//
                localStorage.setItem(
                    "loginDetail",
                    JSON.stringify(loginDetail)
                );

                getPaymentGateway({
                    username: data.username,
                    sessionid: response.data.data.sessionid,
                });
                dispatch(loginServiceSuccess({...responseData, silentLogin: data.silentLogin}));
                dispatch({ type: FETCH_STORES, payload: responseData.stores });
                dispatch(loaderStatus(false));
                if (responseData.userdefault.resetpassword === "Y") {
                    dispatch({ type: FORCE_RESET_PASSWORD });
                }

                if (data.silentLogin !== true) {
                    if (responseData.userdefault.subscriber === "Y") {
                        history.push("/");
                    } else {
                        if (responseData.userdefault.loungeoperator === "Y") {
                            const data = [
                                {
                                    key: "direction",
                                    value: responseData.userdefault.direction,
                                },
                                {
                                    key: "airportid",
                                    value: responseData.userdefault.airportid,
                                },
                            ];
                            dispatch(updateFlightForm(data));
                        }
                        dispatch(productCategory(responseData.categories));
                        history.push("/");
                    }
                } else if (data.silentLogin === true) {
                    if (path) {
                        history.push(path);
                    }
                }
            } else if (response.data.status > 0) {
                dispatch(loaderStatus(false));
                const loginDetail = JSON.parse(
                    sessionStorage.getItem("loginDetail")
                );

                if (loginDetail !== null && loginDetail.terms === true) {
                    localStorage.clear();
                } else {
                    dispatch(loginServiceError(response.data.statusMessage));
                }
            } else {
                dispatch(loaderStatus(false));
                dispatch(loginServiceError(APPLICATION_CONST.message));
            }
        })
        .catch((error) => {            
            dispatch(loginServiceError({error: error.message, isSilent: data.silentLogin}));
        })
        .finally(()=>{
          dispatch(loaderStatus(false));
        });
};
