import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';
import { Card, Select, Pagination, Breadcrumb, Button } from 'antd';
import image1 from '../images/1.jpg';
import image2 from '../images/2.jpg';


class Orders extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    navigateToProductDetails = () => {
        this.props.history.push('/productdetails')
    }

    render() {

        return (
            <>
                <Navbar />

                    <Breadcrumb style={{ margin:20, fontSize:12 }} separator=">">
                        <Breadcrumb.Item>
                            <a href="/">Home</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <a href="">Your Orders</a>
                        </Breadcrumb.Item>        
                    </Breadcrumb>  
                    <div style={{ fontWeight:'bold', padding: 20, fontSize:25 }}>Your Orders</div>

                    <Container>

                        <Row style={{ backgroundColor:'#EEE', marginRight:20, marginLeft:20, padding:10 }}>
                            <Col md={3}>
                                <div style={{ fontWeight:'bold '}}>Order Placed</div>
                                <div>January 4, 2021</div>
                            </Col>
                            <Col md={3}>
                                <div style={{ fontWeight:'bold '}}>Total</div>
                                <div>$5000.00</div>
                            </Col>
                            <Col md={6} style={{ display:'flex', flexDirection:'column', justifyContent:'flex-end' }}>
                                <div style={{ textAlign:'right' }}>Order # : FNVDFB-837474</div>
                                <a href="#"><div style={{ textAlign:'right' }}>Invoice</div></a>
                            </Col>
                        </Row>
                        <Row style={{ border:'0.01em solid #EEE', marginRight:20, marginLeft:20, padding:10 }}>

                            <Col md={3} style={{ padding:0, display:'flex' }}>
                                <img src={image1} style={{ width: 80, height:'auto', marginRight:20 }}/>
                                <div>Armor Air x Fear</div>
                            </Col>

                            <Col md={3} style={{ padding:0, display:'flex' }}>
                               <Button type="primary" onClick={() => this.props.history.push('/shoppingcart')} style={{ marginRight:20 }}>Buy It Again</Button>
                               <Button type="dashed" onClick={() => this.props.history.push('/productdetails')}>View Item</Button>
                            </Col>


                        </Row>

                    </Container>

                    <Container style={{ marginTop:20 }}>

                        <Row style={{ backgroundColor:'#EEE', marginRight:20, marginLeft:20, padding:10 }}>
                            <Col md={3}>
                                <div style={{ fontWeight:'bold '}}>Order Placed</div>
                                <div>January 14, 2021</div>
                            </Col>
                            <Col md={3}>
                                <div style={{ fontWeight:'bold '}}>Total</div>
                                <div>$5000.00</div>
                            </Col>
                            <Col md={6} style={{ display:'flex', flexDirection:'column', justifyContent:'flex-end' }}>
                                <div style={{ textAlign:'right' }}>Order # : HGFHFJK-837474</div>
                                <a href="#"><div style={{ textAlign:'right' }}>Invoice</div></a>
                            </Col>
                        </Row>
                        <Row style={{ border:'0.01em solid #EEE', marginRight:20, marginLeft:20, padding:10 }}>

                            <Col md={3} style={{ padding:0, display:'flex' }}>
                                <img src={image1} style={{ width: 80, height:'auto', marginRight:20 }}/>
                                <div>Armor Air x Fear</div>
                            </Col>

                            <Col md={3} style={{ padding:0, display:'flex' }}>
                            <Button type="primary" onClick={() => this.props.history.push('/shoppingcart')} style={{ marginRight:20 }}>Buy It Again</Button>
                            <Button type="dashed" onClick={() => this.props.history.push('/productdetails')}>View Item</Button>
                            </Col>


                        </Row>

                        <Row style={{ border:'0.01em solid #EEE', marginRight:20, marginLeft:20, padding:10 }}>

                            <Col md={3} style={{ padding:0, display:'flex' }}>
                                <img src={image2} style={{ width: 80, height:'auto', marginRight:20 }}/>
                                <div>Armor Air x Fear</div>
                            </Col>

                            <Col md={3} style={{ padding:0, display:'flex' }}>
                            <Button type="primary" onClick={() => this.props.history.push('/shoppingcart')} style={{ marginRight:20 }}>Buy It Again</Button>
                            <Button type="dashed" onClick={() => this.props.history.push('/productdetails')}>View Item</Button>
                            </Col>


                        </Row>

                    </Container>

                <Footer />
            </>
        )
    }
  }

  
  export default Orders;
  