import React, { Component } from "react";
import { Navbar, Nav, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Input, Space, Drawer, Button } from "antd";
import {
    UserOutlined,
    ShoppingCartOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { signOut } from "../actions/authentication/signOutAction";
import { SUBSCRIBER_LOGIN } from "../config/customConfig";
const { Search } = Input;

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawervisible: false,
        };
    }

    showDrawer = () => {
        this.setState({
            drawervisible: true,
        });
    };

    onClose = () => {
        this.setState({
            drawervisible: false,
        });
    };

    // onSearch = value => console.log(value);

    render() {
        return this.props.minimal === true ? (
            <Navbar bg="light" variant="light" style={{ height: 80 }}>
                <Navbar.Brand
                    href="/"
                    style={{ fontWeight: "bold", color: "#111" }}
                >
                    Shopper
                </Navbar.Brand>
                <Nav></Nav>
            </Navbar>
        ) : (
            <>
                <Navbar bg="light" variant="light" style={{ height: 80 }}>
                    <Navbar.Brand
                        as={Link}
                        to="/"
                        style={{ fontWeight: "bold" }}
                    >
                        Shopper
                    </Navbar.Brand>

                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Search
                            placeholder="Search Products"
                            style={{
                                width: 200,
                                marginRight: 10,
                                marginLeft: 10,
                            }}
                            allowClear
                        />

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <div
                                onClick={() => this.showDrawer()}
                                style={{ marginRight: 10, marginLeft: 10 }}
                            >
                                <UserOutlined className="navBarIconStyle" />
                            </div>
                            <div style={{ marginRight: 10, marginLeft: 10 }}>
                                <Link
                                    to="/shoppingcart"
                                    style={{ position: "relative" }}
                                >
                                    <span
                                        style={{
                                            position: "absolute",
                                            right: 0,
                                            top: -12,
                                            backgroundColor: "green",
                                            color: "#fff",
                                            width: 21,
                                            height: 21,
                                            borderRadius: 15,
                                            textAlign: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {this.props.cartItems.length}
                                    </span>
                                    <ShoppingCartOutlined className="navBarIconStyle" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </Navbar>

                <Navbar
                    style={{ backgroundColor: "#111", height: 50 }}
                    className="justify-content-center"
                >
                    <Nav>
                        <Link to="/categories" className="navLinkStyle">
                            All Categories
                        </Link>
                        <Link
                            to="/products/deals"
                            params={{ type: "deal" }}
                            className="navLinkStyle"
                        >
                            Deals
                        </Link>
                        <Link to="/products/CATEGORY2" className="navLinkStyle">
                            Food
                        </Link>
                        <Link to="/products/CATEGORY1" className="navLinkStyle">
                            Liquor/Drink
                        </Link>
                        <Link to="/products/CATEGORY3" className="navLinkStyle">
                            Souvenirs
                        </Link>
                        <Link to="/products/C0005" className="navLinkStyle">
                            Others
                        </Link>
                    </Nav>
                </Navbar>

                <Drawer
                    width={320}
                    title={
                        <div style={{ padding: 20 }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: "2em",
                                        marginBottom: 20,
                                    }}
                                >
                                    <UserOutlined />
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "column",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {this.props.isAuthed &&
                                this.props.userDetails.username !== SUBSCRIBER_LOGIN.username ? (
                                    <>
                                        <div
                                            style={{
                                                fontSize: 16,
                                                fontWeight: "bold",
                                                textAlign: "center",
                                            }}
                                        >
                                            {`Hello ${this.props.userDetails.firstname} ${this.props.userDetails.lastname}`}
                                        </div>
                                        <div
                                            style={{
                                                fontSize: 12,
                                                textAlign: "center",
                                            }}
                                        >
                                            {this.props.userDetails.email}
                                        </div>
                                    </>
                                ) : (
                                    <div className="text-center">
                                        <div
                                            style={{
                                                fontSize: 14,
                                            }}
                                        >
                                            Hello, welcome to shopper.
                                        </div>
                                        <Button
                                            className="mt-2 mb-2"
                                            type="primary"
                                            onClick={() => { this.props.history.push("/signin",{redirectRoute: "/"}) }}
                                            block
                                        >
                                            SignIn
                                        </Button>
                                        New customer?
                                        <Button
                                            className="mt-2 mb-2"
                                            type="default"
                                            onClick={() => { this.props.history.push("/signup") }}
                                            block
                                        >
                                            Create an account
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    placement="right"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.drawervisible}
                >
                    {this.props.isAuthed && this.props.userDetails.username !== SUBSCRIBER_LOGIN.username ? (
                        <div>
                            <div style={{ marginTop: 10, marginBottom: 20 }}>
                                <a href="/account">Your Account</a>
                            </div>
                            <div style={{ marginTop: 10, marginBottom: 20 }}>
                                <a href="/orders">Orders</a>
                            </div>
                            {/* <div style={{ marginTop:10, marginBottom:20 }}><a href="/payments">Payments</a></div> */}
                            <div style={{ marginTop: 10, marginBottom: 20 }}>
                                <a href="/loginsecurity">Login & Security</a>
                            </div>
                            <div style={{ marginTop: 10, marginBottom: 20 }}>
                                <a href="/wishlist">Wishlist</a>
                            </div>
                            <div style={{ marginTop: 10, marginBottom: 20 }}>
                                <a href="/changepassword">Change Password</a>
                            </div>
                            <div style={{ marginTop: 10, marginBottom: 20 }}>
                                <a
                                    href=""
                                    onClick={() => {
                                        this.props.signOut();
                                        // this.props.history().push("/signin");
                                    }}
                                >
                                    Sign Out
                                </a>
                            </div>
                            
                        </div>
                    ) : (
                        ""
                    )}
                </Drawer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.CartReducer.cart,
    internalUser: state.SignInReducer.logindetails.internal,
    isAuthed: state.SignInReducer.authed,
    userDetails: state.SignInReducer.logindetails.userdefault,
});
export default connect(mapStateToProps, { signOut })(NavBar);
