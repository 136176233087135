import axios from "axios";
import {
  FLIGHT_SERVICE_SUCCESS,
  FLIGHT_SERVICE_PENDING,
  FLIGHT_SERVICE_ERROR,
  UPDATE_FLIGHT_FORM_INFO,
} from "./actionTypes";
import { VIPER_CONST } from "../../config/customConfig";
import { loaderStatus } from "../vendor/loaderAction";

export const emptyFlightForm = () => (dispatch) => {
  const data = [
    { key: "airline", value: null },
    { key: "flightId", value: null },
    { key: "targetDate", value: null },
    { key: "traveldate", value: null },
    { key: "departureDate", value: null },
    // { key: "airportid", value: null },
    // { key: "direction", value: null },
    { key: "flighttime", value: null },
    { key: "scheduleId", value: null },
    { key: "targetDate", value: null },
    { key: "traveldate", value: null },
  ];

  dispatch(updateFlightForm(data));
};

export const updateFlightForm = (data) => (dispatch) => {
  dispatch({ type: UPDATE_FLIGHT_FORM_INFO, payload: data });
};

export const getFlightSchedule = (flighData) => (dispatch) => {
  const body = {
    username: VIPER_CONST.username,
    sessionid: VIPER_CONST.sessionid,
    failstatus: 0,
    request: {
      direction: flighData.direction,
      airportid: flighData.airportid,
      traveldate: flighData.traveldate,
    },
  };

  dispatch(serviceActionPending());
  dispatch(loaderStatus(true));
  axios({
    method: "post",
    url: `${VIPER_CONST.url}getschedule`,
    data: body,
  })
    .then((response) => {
      dispatch(loaderStatus(false));
      if (response.data.status === 0) {
        dispatch(serviceActionSuccess(response.data.data));
      } else if (response.data.status > 0) {
        dispatch(serviceActionError(response.data.statusMessage));
      } else {
        dispatch(
          serviceActionError("Something went wrong! Please try again later")
        );
        alert("Something went wrong! Please try again later");
      }
    })
    .catch((error) => {
      dispatch(serviceActionError(error.message));
      dispatch(loaderStatus(false));
      alert(error.message);
    });
};

export const serviceActionPending = () => ({
  type: FLIGHT_SERVICE_PENDING,
});

export const serviceActionError = (error) => ({
  type: FLIGHT_SERVICE_ERROR,
  payload: error,
});

export const serviceActionSuccess = (data) => ({
  type: FLIGHT_SERVICE_SUCCESS,
  payload: data,
});
