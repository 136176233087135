import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { Button, Input, Form } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { callLoginService } from "../actions/authentication/signInAction";
import { connect } from "react-redux";
import md5 from "md5";

class SignIn extends React.Component {

    usernameInputRef= React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            showErrorLabel: false,
            redirectRoute: this.props.location.state && this.props.location.state.redirectRoute || '/',
            silentLogin: this.props.location.state && this.props.location.state.silentLogin || false
        };
    }


    handleLogin = () => {
        if(this.props.signingIn) return;
        let username = this.state.username;
        let password = this.state.password;
        if (username === "" || password === "") return;
        this.setState({showErrorLabel: true});
        const data = {
            failstatus: 0,
            username: username,
            password: md5(password),
            terms: true,
            silentLogin: this.state.silentLogin,
            internal: "N",
        };
        this.props.callLoginService(
            data,
            this.props.history,
            this.state.redirectRoute
        );
    };

    closeDialog = () => {
        this.setState({
            displayDialog: false,
        });
    };

    componentDidMount(){
        this.usernameInputRef.current.focus();
    }

    render() {
        return (
            <>
                <Navbar minimal />
                <div
                    style={{
                        fontWeight: "bold",
                        fontSize: 26,
                        textAlign: "center",
                        marginTop: 50,
                    }}
                >
                    SHOPPER
                </div>

                <div
                    style={{
                        fontWeight: "bold",
                        fontSize: 16,
                        textAlign: "center",
                        marginTop: 50,
                    }}
                >
                    Sign In
                </div>
                <Container
                    fluid
                    style={{
                        marginTop: 10,
                        marginBottom: 10,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Row>
                        <div style={{ width: 300 }}>
                            <Form                                
                                name="normal_login"
                                className="login-form"
                                initialValues={{ remember: true }}
                                onFinish={this.handleLogin}
                                autoComplete="off"
                                onFieldsChange={()=> {
                                    if(this.state.showErrorLabel)
                                    {
                                        this.setState({showErrorLabel: false})
                                    }
                                }}
                            >
                                <Form.Item
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please input your Username!",
                                        },
                                    ]}
                                >
                                    <Input
                                        ref={this.usernameInputRef}
                                        prefix={
                                            <UserOutlined className="site-form-item-icon" />
                                        }
                                        placeholder="Username"
                                        value={this.state.username}
                                        onChange={(evt) =>
                                            this.setState({
                                                username: evt.target.value,
                                            })
                                        }
                                        disabled={this.props.signingIn}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please input your Password!",
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={
                                            <LockOutlined className="site-form-item-icon" />
                                        }
                                        type="password"
                                        placeholder="Password"
                                        value={this.state.password}
                                        onChange={(evt) => {
                                            this.setState({
                                                password: evt.target.value,
                                            });
                                        }}
                                        autoComplete="off"
                                        disabled={this.props.signingIn}
                                    />
                                </Form.Item>

                                <span className="text-danger  mb-4">
                                        {
                                            this.state.showErrorLabel && this.props.error
                                        }
                                </span>

                                <Form.Item>
                                    <Button
                                        block
                                        type="primary"
                                        htmlType="submit"
                                        className="login-form-button"
                                        loading={this.props.signingIn}                                        
                                    >
                                        { this.props.signingIn?"Signing in...": "Sign in"}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div style={{ fontWeight: "bold" }}>
                                New to Shopper? <Link to="/signup">Sign Up</Link>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div style={{ fontWeight: "bold" }}>
                                Forgot Password?
                                <Link to="/resetpassword"> Reset Password</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Footer minimal />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    authed: state.SignInReducer.authed,
    logindetails: state.SignInReducer.logindetails,
    error: state.SignInReducer.error,
    signingIn: state.SignInReducer.signingIn
});
export default connect(mapStateToProps, { callLoginService })(SignIn);
