import Home from "../screens/Home";
import Categories from "../components/Categories";
import ProductDetails from "../components/ProductDetails";
import ShoppingCart from "../components/ShoppingCart";
import PaymentDetails from "../components/PaymentDetails";
import Confirmation from "../components/Confirmation";
import Products from "../components/Products";
import SignUp from "../components/SignUp";
import SignIn from "../components/SignIn";
import Account from "../components/Account";
import Orders from "../components/Orders";
import FailedConfirmation from "../components/FailedConfirmation";
import LoginSecurity from "../components/LoginSecurity";
import ChangePassword from "../components/ChangePassword";

const routes = [
  {
    path: "/",
    component: Home,
    exact: true,
    role: ["subscriber"],
  },

  {
    path: "/categories",
    component: Categories,
    exact: true,
    role: ["subscriber"],
  },
  {
    path: "/productdetails/:productId",
    component: ProductDetails,
    exact: true,
    role: ["subscriber"],
  },
  {
    path: "/shoppingcart",
    component: ShoppingCart,
    exact: true,
    role: ["subscriber"],
  },

  {
    path: "/paymentdetails",
    component: PaymentDetails,
    exact: true,
    role: ["subscriber"],
  },
  {
    path: "/confirmation",
    component: Confirmation,
    exact: true,
    role: ["subscriber"],
  },
  {
    path: "/failedconfirmation",
    component: FailedConfirmation,
    exact: true,
    role: ["subscriber"],
  },
  {
    path: "/products",
    component: Products,
    exact: true,
    role: ["subscriber"],
  },
  {
    path: "/products/:categoryId",
    component: Products,
    exact: true,
    role: ["subscriber"],
  },
  {
    path: "/signup",
    component: SignUp,
    exact: true,
    role: ["subscriber"],
  },
  {
    path: "/signin",
    component: SignIn,
    exact: true,
    role: ["subscriber"],
  },
  {
    path: "/account",
    component: Account,
    exact: true,
    role: ["subscriber"],
  },
  {
    path: "/orders",
    component: Orders,
    exact: true,
    role: ["subscriber"],
  },
  {
    path: "/loginsecurity",
    component: LoginSecurity,
    exact: true,
    role: ["vendor"],
  },
  {
    path: "/changepassword",
    component: ChangePassword,
    exact: true,
    role: ["subscriber"],
  },
];

export default routes;
